import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import CryptoJS from "crypto-js"

import { ClientMessageType } from "../../api/enum";
import { ClientLogonRequest } from "../../api/request";
import { ClientResponseContainer, ClientLogonResponse } from "../../api/response";
import { APIContext } from "..";

import { Button, Card, Form } from "react-bootstrap";
import { LanguageMap } from "../../functions/Language";

interface LoginInfo {
    username: string
    password: string
}

function LoginPage() {
    const { isLogon, isForceLogout, endpoint, language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.login, [language])
    const navigate = useNavigate()

    const [errMsg, setErrMsg] = useState("")

    const [saveLoginInfo, setSaveLoginInfo] = useState(localStorage.getItem("loginInfo")!==null&&localStorage.getItem("loginInfo")!.indexOf("\"\"")===-1)
    const LoginInfo = useRef<LoginInfo|null>(null)
    const onmessage = useCallback((container: ClientResponseContainer)=>{
        if (container.message_header_.message_type_ === ClientMessageType.kLogon){
            const resp = new ClientLogonResponse(container)

            if (!resp.success_){
                setErrMsg(textMap.wrong_username_or_password)
                LoginInfo.current = null
                return
            }

            if (LoginInfo.current !== null){
                localStorage.setItem("loginInfo", JSON.stringify(LoginInfo.current))
            }

            navigate("/")
        }
    }, [textMap, navigate])

    const formHandle = useCallback<React.FormEventHandler<HTMLFormElement>>((event)=>{
        event.preventDefault()
        event.stopPropagation()
        if (!endpoint || LoginInfo.current !== null) return

        const formData = new FormData(event.currentTarget)
        const [username, password] = [formData.get("username")!.toString(), formData.get("password")!.toString()]

        if (username.length === 0 || password.length === 0) {
            setErrMsg(textMap.username_password_cannot_be_empty)
            return
        }

        if (/^[A-Za-z\d][A-Za-z\d-]+[A-Za-z\d]$/.test(username) === false) {
            setErrMsg(textMap.username_invalid)
            return
        }

        LoginInfo.current = {
            username: username,
            password: saveLoginInfo?password:""
        }

        const sha256_password = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex)
        const req = new ClientLogonRequest(username, sha256_password)

        endpoint.send(req)
    }, [textMap, endpoint, saveLoginInfo])

    useEffect(() => {
        if (!endpoint) return

        if (isLogon) navigate("/")

        if (endpoint.on_message !== onmessage) {
            endpoint.on_message = onmessage
        }
    }, [isLogon, endpoint, navigate, onmessage])

    return useMemo(()=>{
        const logininfo : LoginInfo = JSON.parse(localStorage.getItem("loginInfo")??'{"username": "", "password": ""}')

        return (
            <Card style={{width: 450, maxWidth: "95%", margin: "auto", transform: "translateY(calc((100vh - 100% - 64px) / 2)"}}>
                <Card.Header>{textMap.login}</Card.Header>
                <Card.Body>
                    <Form style={{display: "flex", flexDirection: 'column', gap: 16}} onSubmit={formHandle} onChange={()=>setErrMsg("")}>
                        <Form.Group>
                            <Form.Label>{textMap.username}:</Form.Label>
                            <Form.Control type="text" name="username" maxLength={32} defaultValue={logininfo.username}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>{textMap.password}:</Form.Label>
                            <Form.Control type="password" name="password" defaultValue={logininfo.password}/>
                        </Form.Group>
                        <Form.Check type="switch" name="saveLoginInfo" checked={saveLoginInfo} label={textMap.remember_password} onChange={()=>setSaveLoginInfo(p=>!p)}/>
                        <span style={{display: errMsg!==""?"block":"none", color: "red"}}>{errMsg}</span>
                        <Button type="submit">{textMap.login}</Button>
                    </Form>
                </Card.Body>
                {
                    isForceLogout === null ? <></> :
                    isForceLogout === false ? <Card.Footer>{textMap.logout_success}</Card.Footer> :
                    <Card.Footer>{textMap.logon_from_another_place}</Card.Footer>
                }
            </Card>
        )
    }, [textMap, isForceLogout, formHandle, errMsg, saveLoginInfo])
}

export default LoginPage