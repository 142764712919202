// function
import React, { useMemo, useContext, useRef, useEffect, useState, useCallback } from "react"

// private function
import { OptionSide } from "../api/enum"
import { JumpPoint } from "../api/types"
import { ClientRetrieveJumpPointResponse } from "../api/response"
import { D2OptionInfo, OptionInfo } from "../functions/OptionInfo"
import { ParseNumber } from "../functions/NumberProcessor"
import { DateStringType, From_Nanosecond_To_DateString } from "../functions/DateTimeHandler"
// import { GetSpreadTableType, ReturnNextUpPrice } from "../../../functions/SpreadTable"
import { LanguageMap } from "../functions/Language"
import { APIContext } from "../pages"

// ui component
import { Button, Card, Form, FormCheck, InputGroup, Table } from "react-bootstrap"
import { ClientRetrieveJumpPointRequest } from "../api/request"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleArrowDown, faCircleArrowUp } from "@fortawesome/free-solid-svg-icons"

export interface JumpPointListRowProps {
    jumppoint       : JumpPoint
    optionInfo      : OptionInfo
    displayVolume   : boolean
    displayRatio    : boolean
}

function JumpPointListRow({ jumppoint, optionInfo, displayVolume, displayRatio }: JumpPointListRowProps){
    return useMemo(()=>{
        const x = jumppoint

        const prev_total = x.u_prev_bid_size_ + x.u_prev_ask_size_
        const prev_br = Math.round((optionInfo.option_side_ === OptionSide.kCallorBull ? (x.u_prev_ask_size_ / prev_total) : (x.u_prev_bid_size_ / prev_total)) * 1000) / 10
        const prev_sr = Math.round(1000 - prev_br * 10) / 10
        // const prev_mp = ReturnNextUpPrice(x.u_prev_bid_, GetSpreadTableType(optionInfo!.underlying_)) === x.u_prev_ask_ ? (
        //     Math.round((x.u_prev_bid_ * x.u_prev_ask_size_ * 100 + x.u_prev_ask_ * x.u_prev_bid_size_ * 100) / prev_total) / 100
        // ): Math.round((x.u_prev_bid_ + x.u_prev_ask_) / 2)
        const curr_total = x.u_bid_size_ + x.u_ask_size_
        const br = Math.round((optionInfo.option_side_ === OptionSide.kCallorBull ? (x.u_ask_size_ / curr_total) : (x.u_bid_size_ / curr_total)) * 1000) / 10
        const sr = Math.round(1000 - br * 10) / 10
        // const mp = ReturnNextUpPrice(x.u_bid_, GetSpreadTableType(optionInfo!.underlying_)) === x.u_ask_ ? (
        //     Math.round((x.u_bid_ * x.u_ask_size_ * 100 + x.u_ask_ * x.u_bid_size_ * 100) / curr_total) / 100
        // ): Math.round((x.u_bid_ + x.u_ask_) / 2)
                                        
        return (
            <tr style={{textAlign: "right"}}>
                <td style={{textAlign: "left"}}>{From_Nanosecond_To_DateString(x.timestamp_, DateStringType.hhmmss_millisecond)}</td>
                {
                    x.d_bid_ === x.d_prev_bid_?<td colSpan={2}>{(x.d_bid_ / 1000).toString()}</td> :
                    <>
                        <td>{x.d_prev_bid_ / 1000}</td>
                        <td className={"text-white " + (x.d_bid_ > x.d_prev_bid_ ? "bg-success" : "bg-danger")}>
                            {x.d_bid_ / 1000}&nbsp;
                            <FontAwesomeIcon icon={x.d_bid_ > x.d_prev_bid_?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>
                }
                {
                    x.d_ask_ === x.d_prev_ask_?<td colSpan={2}>{(x.d_ask_ / 1000).toString()}</td> :
                    <>
                        <td>{x.d_prev_ask_ / 1000}</td>
                        <td className={"text-white " + (x.d_ask_ > x.d_prev_ask_ ? "bg-success" : "bg-danger")}>
                            {x.d_ask_ / 1000}&nbsp;
                            <FontAwesomeIcon icon={x.d_ask_ > x.d_prev_ask_?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>
                }
                {
                    x.u_bid_ === x.u_prev_bid_?<td colSpan={2}>{(x.u_bid_ / 1000).toString()}</td> :
                    <>
                        <td>{x.u_prev_bid_ / 1000}</td>
                        <td className={"text-white " + (x.u_bid_ > x.u_prev_bid_ ? "bg-success" : "bg-danger")}>
                            {x.u_bid_ / 1000}&nbsp;
                            <FontAwesomeIcon icon={x.u_bid_ > x.u_prev_bid_?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>
                }
                {
                    x.u_ask_ === x.u_prev_ask_?<td colSpan={2}>{(x.u_ask_ / 1000).toString()}</td> :
                    <>
                        <td>{x.u_prev_ask_ / 1000}</td>
                        <td className={"text-white " + (x.u_ask_ > x.u_prev_ask_ ? "bg-success" : "bg-danger")}>
                            {x.u_ask_ / 1000}&nbsp;
                            <FontAwesomeIcon icon={x.u_ask_ > x.u_prev_ask_?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>
                }
                {
                    displayVolume?
                    x.u_bid_size_ === x.u_prev_bid_size_?<td colSpan={2}>{ParseNumber(x.u_bid_size_)}</td> :
                    <>
                        <td>{ParseNumber(x.u_prev_bid_size_)}</td>
                        <td className={"text-white " + (x.u_bid_size_ > x.u_prev_bid_size_ ? "bg-success" : "bg-danger")}>
                            {ParseNumber(x.u_bid_size_)}&nbsp;
                            <FontAwesomeIcon icon={x.u_bid_size_ > x.u_prev_bid_size_?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>:
                    <></>
                }
                {
                    displayVolume?
                    x.u_ask_size_ === x.u_prev_ask_size_?<td colSpan={2}>{ParseNumber(x.u_ask_size_)}</td> :
                    <>
                        <td>{ParseNumber(x.u_prev_ask_size_)}</td>
                        <td className={"text-white " + (x.u_ask_size_ > x.u_prev_ask_size_ ? "bg-success" : "bg-danger")}>
                            {ParseNumber(x.u_ask_size_)}&nbsp;
                            <FontAwesomeIcon icon={x.u_ask_size_ > x.u_prev_ask_size_?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>:
                    <></>
                }
                {
                    displayRatio?
                    br === prev_br?<td colSpan={2}>{br}%</td>:
                    <>
                        <td>{prev_br}%</td>
                        <td className={"text-white " + (br > prev_br ? "bg-success" : "bg-danger")}>
                            {br}%&nbsp;
                            <FontAwesomeIcon icon={br > prev_br?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>:
                    <></>
                }
                {
                    displayRatio?
                    sr === prev_sr?<td colSpan={2}>{sr}%</td> :
                    <>
                        <td>{prev_sr}%</td>
                        <td className={"text-white " + (sr > prev_sr ? "bg-success" : "bg-danger")}>
                            {sr}%&nbsp;
                            <FontAwesomeIcon icon={sr > prev_sr?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>:
                    <></>
                }
                {/*
                    mp === prev_mp?<td colSpan={2}>{mp / 1000}</td> :
                    <>
                        <td>{(prev_mp / 1000).toFixed(3)}</td>
                        <td className={"text-white " + (mp > prev_mp ? "bg-success" : "bg-danger")}>
                            {(mp / 1000).toFixed(3)}&nbsp;
                            <FontAwesomeIcon icon={mp > prev_mp?faCircleArrowUp:faCircleArrowDown}/>
                        </td>
                    </>
                */}
            </tr>
        )
    }, [jumppoint, optionInfo, displayRatio, displayVolume])
}

export interface JumpPointListProps {
    selectedOption: number|null
    setSelectedOption: React.Dispatch<React.SetStateAction<number|null>>
    jumppoint: ClientRetrieveJumpPointResponse|null
}

function JumpPointList({selectedOption, setSelectedOption, jumppoint}: JumpPointListProps){
    const { timestamp, endpoint, language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.component.jump_point_list, [language])

    const JumpPointRequestEpoch = useRef<number>(-1)

    const OptionInput = useRef<HTMLInputElement>(null)
    useEffect(()=>{
        if (OptionInput.current && selectedOption)
            OptionInput.current.value = selectedOption.toString()
    }, [selectedOption])

    const optionInfo = useMemo(()=>{
        if (selectedOption === null) return null

        const info = D2OptionInfo.get(selectedOption)
        JumpPointRequestEpoch.current = -1 // reset for sending retrieve request

        return typeof info === "undefined" ? null : info
    }, [selectedOption])

    const formHandle = useCallback<React.FormEventHandler<HTMLFormElement>>((event)=>{
        event.preventDefault()
        event.stopPropagation()

        const formData = new FormData(event.currentTarget)
        const symbol = parseInt((formData.get("symbol")??"NaN").toString())

        if (isNaN(symbol) || !D2OptionInfo.has(symbol)){
            OptionInput.current!.value = selectedOption!==null?selectedOption.toString():""
            return
        }

        setSelectedOption(symbol)
    }, [setSelectedOption, selectedOption])

    useEffect(()=>{
        if (optionInfo === null) return
        
        if (timestamp - JumpPointRequestEpoch.current > 15000){
            JumpPointRequestEpoch.current = timestamp
            endpoint.send(new ClientRetrieveJumpPointRequest(optionInfo.symbol_))
        }
    }, [endpoint, timestamp, optionInfo])

    const [displayRatio, setDisplayRatio] = useState<boolean>(true)
    const [displayVolume, setDisplayVolume] = useState<boolean>(true)

    return useMemo(()=>{
        const totalColumn = 9 - (displayRatio?0:2) - (displayVolume?0:2)
        const width = (1 / totalColumn * 100) + "%"

        return (
            <Card>
                <Card.Header>{textMap.name}</Card.Header>
                <Card.Body style={{padding: "0.75rem"}}>
                    <Form style={{marginBottom:8, display: "flex", flexDirection: "row", gap: 8, flexWrap: "wrap"}} onSubmit={formHandle}>
                        <div style={{display: "flex", flexDirection: "row", gap: 8, minWidth: 180}} className="me-auto">
                            <InputGroup size="sm" style={{maxWidth: 150}}>
                                <InputGroup.Text style={{padding: "0.125rem 0.5rem"}}>{textMap.dcode}</InputGroup.Text>
                                <Form.Control type="text" style={{padding: "0.125rem 0.5rem"}} name="symbol" ref={OptionInput}/>
                            </InputGroup>
                            <Button type="submit" style={{padding: "0.125rem 0.5rem"}} size="sm">{textMap.search}</Button>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", gap: 8}}>
                            <FormCheck type="switch" label={textMap.ratio} checked={displayRatio} style={{padding: "0.125rem 0rem 0.125rem 2.5rem"}} onChange={()=>setDisplayRatio(p=>!p)}/>
                            <FormCheck type="switch" label={textMap.volume} checked={displayVolume} style={{padding: "0.125rem 0rem 0.125rem 2.5rem"}} onChange={()=>setDisplayVolume(p=>!p)}/>
                        </div>
                    </Form>
                    {
                        selectedOption === null ? textMap.no_option_selected :
                        jumppoint === null ? textMap.no_jump_point_data_found :
                        optionInfo === null ? textMap.no_option_data_found :
                        jumppoint.jump_point_length_ === 0 ? textMap.no_jump_point_found :
                        <div style={{width: "100%", maxHeight: 650, overflow: "auto"}}>
                            <Table bordered striped hover style={{marginBottom: 0}}>
                                <thead className="bg-light sticky-top top-0" style={{boxShadow: "0 -1px 0 #dee2e6, inset 0 -1px 5px #dee2e6"}}>
                                    <tr>
                                        <th style={{textAlign: "center", width: width}}>{textMap.time}</th>
                                        <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>{textMap.dbid}</th>
                                        <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>{textMap.dask}</th>
                                        <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>{textMap.ubid}</th>
                                        <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>{textMap.uask}</th>
                                        {
                                            displayVolume?
                                            <>
                                                <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>{textMap.ubidsize}</th>
                                                <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>{textMap.uasksize}</th>
                                            </>:
                                            <></>
                                        }
                                        {
                                            displayRatio?
                                            <>
                                                <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>BR</th>
                                                <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>SR</th>
                                            </>:
                                            <></>
                                        }
                                        {/* <th style={{textAlign: "center", width: width, minWidth: "12rem"}} colSpan={2}>MP</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        jumppoint.jump_points_.map((x, i) => {
                                            return <JumpPointListRow key={i} optionInfo={optionInfo} jumppoint={x} displayRatio={displayRatio} displayVolume={displayVolume}/>
                                        }).reverse()
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                </Card.Body>
            </Card>
        )
    }, [textMap, selectedOption, formHandle, jumppoint, optionInfo, displayRatio, displayVolume])
}

export default JumpPointList