import { useContext, useMemo } from "react"

import { LanguageMap } from "../../../functions/Language"
import { APIContext } from "../.."

import { Card } from "react-bootstrap"

import main from "../image/login/main.png"

function Login() {
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.tutorial.login, [language])

    return useMemo(()=>{
        return (
            <Card>
                <Card.Header>{textMap.title}</Card.Header>
                <Card.Body>
                    <Card.Title>{textMap.login_form_}</Card.Title>
                    <img src={main} alt="login page's form" style={{maxWidth: "100%"}}/>
                    <Card.Text>1. {textMap.login_form_step1_}</Card.Text>
                    <Card.Text>2. {textMap.login_form_step2_}</Card.Text>
                    <Card.Text>3. {textMap.login_form_step3_}</Card.Text>
                </Card.Body>
            </Card>
        )
    }, [textMap])
}

export default Login