// function
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react"

// private function
import { Side } from "../../../api/enum";
import { ORDERBOOK_DEPTH } from "../../../api/constants";
import { ClientQuoteResponse } from "../../../api/response";
import { U2OptionInfos } from "../../../functions/OptionInfo";
import { GetSpreadTableType, ReturnNextNDownPrice, ReturnNextNUpPrice } from "../../../functions/SpreadTable";
import { LanguageMap } from "../../../functions/Language";
import { APIContext } from "../..";

// ui component
import { Button, Card, Form, InputGroup, Table } from "react-bootstrap";

export interface OrderBookProps {
    quote: ClientQuoteResponse | null
    selectedUnderlying: number | null
    setSelectedUnderlying: React.Dispatch<React.SetStateAction<number|null>>
    setSelectedSide: React.Dispatch<React.SetStateAction<Side|null>>
}

function OrderBook({ quote, selectedUnderlying, setSelectedUnderlying, setSelectedSide }: OrderBookProps){
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.orderbook_screener.order_book, [language])

    const UnderlyingInput = useRef<HTMLInputElement>(null)
    useEffect(()=>{
        if (UnderlyingInput.current && selectedUnderlying != null)
            UnderlyingInput.current.value = selectedUnderlying.toString()
    }, [selectedUnderlying])

    const onsubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((event)=>{
        event.preventDefault()
        event.stopPropagation()

        const formData = new FormData(event.currentTarget)
        const underlying = parseInt(formData.get("underlying")!.toString())

        if (U2OptionInfos.has(underlying)){
            setSelectedUnderlying(underlying)
            setSelectedSide(null)
        }
    }, [setSelectedUnderlying, setSelectedSide])

    return useMemo(()=>{
        const max_bid_size_ = !quote?0:quote.bid_size_.reduce((p,c)=>p>c?p:c, 0)
        const max_ask_size_ = !quote?0:quote.ask_size_.reduce((p,c)=>p>c?p:c, 0)
        const max_size_ = Math.max(max_bid_size_, max_ask_size_)

        return (
            <Card style={{flex: 2, minWidth: 300, flexShrink: 0}}>
                <Card.Header>{textMap.name}{!quote?"":(" - "+quote.symbol_)}</Card.Header>
                <Card.Body style={{padding: "0.75rem", display: "flex", flexDirection: "column", gap: "0.5rem", height: "28.75rem", maxHeight: "100rem"}}>
                    <Form style={{display: "flex", flexDirection: "row", gap: "0.5rem"}} onSubmit={onsubmit}>
                        <InputGroup size="sm" style={{maxWidth: 150}}>
                            <InputGroup.Text>U</InputGroup.Text>
                            <Form.Control ref={UnderlyingInput} type="text" name="underlying" size="sm"/>
                        </InputGroup>
                        <Button type="submit" size="sm">{textMap.search}</Button>
                    </Form>
                    {
                        selectedUnderlying === null ? <Card.Text>{textMap.no_underlying_selected}</Card.Text> :
                        quote === null? <Card.Text>{textMap.no_quote_found}</Card.Text> :
                        <Table size="sm" bordered style={{marginBottom: 0}}>
                            <thead>
                                <tr>
                                    <th colSpan={2} style={{textAlign: "center"}} className="bg-success text-white">{textMap.bid}</th>
                                    <th colSpan={2} style={{textAlign: "center"}} className="bg-danger text-white">{textMap.ask}</th>
                                </tr>
                                <tr>
                                    <th style={{textAlign: "center", width: "30%"}}>{textMap.size}</th>
                                    <th style={{textAlign: "center", width: "20%"}}>{textMap.price}</th>
                                    <th style={{textAlign: "center", width: "20%"}}>{textMap.price}</th>
                                    <th style={{textAlign: "center", width: "30%"}}>{textMap.size}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Array.from(Array(ORDERBOOK_DEPTH).keys()).map((v)=>{
                                        const bid_price_ = ReturnNextNDownPrice(quote.best_bid_, v, GetSpreadTableType(quote.symbol_))
                                        const ask_price_ = ReturnNextNUpPrice(quote.best_ask_, v, GetSpreadTableType(quote.symbol_))

                                        const bid_ratio = max_size_===0 ? 0 : (quote.bid_size_[v] / max_size_)
                                        const ask_ratio = max_size_===0 ? 0 : (quote.ask_size_[v] / max_size_)

                                        return (
                                            <tr key={v}>
                                                <td style={{textAlign: "left", padding: 0}}>
                                                    <div style={{width: "100%", position: "relative", lineHeight: "2rem"}}>
                                                        <span>{quote.bid_size_[v]}</span>
                                                        <div style={{display: bid_ratio===0?"none":"block", minWidth: (bid_ratio * 100).toString()+"%", position: "absolute", top: 0, right: 0, backgroundColor: "rgba(0,255,0,0.3)"}}>&nbsp;</div>
                                                    </div>
                                                </td>
                                                <td style={{textAlign: "center"}}>{bid_price_===null?"":(bid_price_ / 1000)}</td>
                                                <td style={{textAlign: "center"}}>{ask_price_ / 1000}</td>
                                                <td style={{textAlign: "right", padding: 0}}>
                                                    <div style={{width: "100%", position: "relative", lineHeight: "2rem"}}>
                                                        <span>{quote.ask_size_[v]}</span>
                                                        <div style={{display: ask_ratio===0?"none":"block", minWidth: (ask_ratio * 100).toString()+"%", position: "absolute", top: 0, left: 0, backgroundColor: "rgba(255,0,0,0.3)"}}>&nbsp;</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </Card.Body>
            </Card>
        )
    }, [textMap, quote, selectedUnderlying, onsubmit])
}

export default OrderBook