import { createContext, useState, useRef, useEffect, useMemo, useContext, useCallback } from "react"
import { Route, Routes } from "react-router-dom"

import { LoadRef } from "../functions/RefLoader"
import { Endpoint } from "../api/endpoint"
import { Language, LanguageMap } from "../functions/Language"

import { Card, Container, Nav, Navbar } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"

import PageNotFound from "./404"
import HomePage from "./HomePage"
import LoginPage from "./LoginPage"
import OrderBookScreenerPage from "./OrderbookScreenerPage"
import TradeQuantityScreenerPage from "./TradeQuantityScreenerPage"
import TutorialPage from "./TutorialPage"
import { ClientLogoutRequest } from "../api/request"

export interface APIContextData {
    language: Language,
    isLogon: boolean|null,
    isForceLogout: boolean|null,
    timestamp: number,
    endpoint: Endpoint
}
const APIContext = createContext<APIContextData>({language: Language.en, isLogon: null, isForceLogout: null, timestamp: 0, endpoint: new Endpoint()})

function TopNav() {
    const { isLogon, language, endpoint } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.component.topnav, [language])

    const logoutStart = useCallback(()=>{
        const uuid = localStorage.getItem("userUUID")
        if (!endpoint || !isLogon || !uuid) return

        endpoint.send(new ClientLogoutRequest(uuid))
    }, [endpoint, isLogon])

    return useMemo(()=>{
        return (
            <Navbar expand="lg" className="bg-body-tertiary" variant="black">
                <Container>
                    <Navbar.Brand>{textMap.name}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="topnav-collapse"/>
                    <Navbar.Collapse id="topnav-collapse" className="justify-content-between">
                        <Nav className="me-auto">
                            <LinkContainer to="/">
                                <Nav.Link>{textMap.home}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/tutorial">
                                <Nav.Link>{textMap.tutorial}</Nav.Link>
                            </LinkContainer>
                        </Nav>
                        <Nav>
                            {
                                isLogon === false ? <LinkContainer to="/login"><Nav.Link>{textMap.login}</Nav.Link></LinkContainer> : 
                                isLogon === true  ? <Nav.Link onClick={logoutStart}>{textMap.logout}</Nav.Link> : 
                                <></>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }, [textMap, isLogon, logoutStart])
}

function Page() {
    const [refLoadSuccess, setRefLoadSuccess] = useState<boolean|null>(null)
    const [connectState, setConnectState] = useState<boolean|number|null>(null)
    const [isLogon, setIsLogon] = useState<boolean|null>(localStorage.getItem("userUUID")===null?false:null)
    const [isForceLogout, setIsForceLogout] = useState<boolean|null>(null)
    const endpoint = useRef<Endpoint|null>(null)

    const refFileLoadStarted = useRef<boolean>(false)
    const reconnectTimer = useRef<number|NodeJS.Timer>(-1)
    useEffect(()=>{
        //==============================
        // Ref File Load
        //==============================

        // start ref file loading
        if (refFileLoadStarted.current === false){
            refFileLoadStarted.current = true
            LoadRef(setRefLoadSuccess)
        }
        // if loading process not succes, then return, otherwise goto endpoint connection
        if (refLoadSuccess !== true) return

        //==============================
        // Endpoint Connection
        //==============================

        // if timer exist, then return, otherwise keep process
        if (reconnectTimer.current !== -1) return
        
        // if connection alive, then return, otherwise goto reconnection process
        if (connectState === true) return

        // if connectState is null, then start connect, otherwise goto connection failure process
        if (connectState === null){
            if (endpoint.current === null)
                endpoint.current = new Endpoint()
            endpoint.current.connect(setConnectState, setIsLogon, setIsForceLogout)
            return
        }

        // if connection failure, then start a waiting timer, otherwise goto reconnect countdown timer
        if (connectState === false) {
            reconnectTimer.current = setTimeout(() => {
                reconnectTimer.current = -1
                setConnectState(10)
            }, 5000)
            return
        }

        // reconnect count down timer
        // when prev - 1 === 0, reload page for reconnection and update to new code
        reconnectTimer.current = setTimeout(()=>{
            reconnectTimer.current = -1
            setConnectState((prev) => {
                if (prev as number - 1 === 0) {
                    // reconnect only
                    // return null

                    // reload for update web page and reconnection
                    window.location.reload()
                    return prev
                }
                return prev as number - 1
            })
        }, 1000)
    }, [refLoadSuccess, connectState])

    const [timestamp, setTimestamp] = useState<number>(0)
    const timeProvider = useRef<NodeJS.Timer|number>(-1)
    useEffect(()=>{
        if (timeProvider.current !== -1) return

        timeProvider.current = setInterval(()=>{
            setTimestamp(Date.now())
        }, 100)
    }, [])

    const language : Language = useMemo(()=>{
        if (timestamp === 0) return Language.en
        const browserLanguage = localStorage.getItem("language")??navigator.language

        if (browserLanguage === "zh-HK" || browserLanguage === "zh-TW")
            return Language.zhTC
        
        if (browserLanguage === "zh-CN" || browserLanguage === "zh")
            return Language.zhSC

        return Language.en
    }, [timestamp])

    return useMemo(()=>{
        if (connectState !== true || endpoint === null) {
            return (
                <Card className="shadow" style={{width: 450, maxWidth: "95%", margin: "auto", transform: "translateY(calc(100vh / 2 - 50%))"}}>
                    <Card.Body>
                        <Card.Title>{!refLoadSuccess ? "Ref Files Loader" : "Endpoint Connector"}</Card.Title>
                        <p>
                            {
                                refLoadSuccess === null ? "Loading Ref Files" :
                                refLoadSuccess === false ? "Ref Files Load Failure" :
                                connectState === null  ? "Trying to Connect" :
                                connectState === false ? "Connection Lost"   :
                                "Reconnect in " + connectState.toString() + " second(s)"
                            }
                        </p>
                    </Card.Body>
                </Card>
            )
        }

        return (
            <div>
                <APIContext.Provider value={{language: language, isLogon: isLogon, isForceLogout: isForceLogout, timestamp: timestamp, endpoint: endpoint.current!}}>
                    <TopNav/>
                    <Container fluid style={{marginTop: 8, marginBottom: 8}}>
                        <Routes>
                            <Route path="tutorial/*" element={<TutorialPage />} />
                            <Route path="login" element={<LoginPage />} />
                            <Route path="orderbookscreener" element={<OrderBookScreenerPage />} />
                            <Route path="tradequantityscreener" element={<TradeQuantityScreenerPage />} />

                            <Route path="" element={<HomePage />} />
                            <Route path="*" element={<PageNotFound/>} />
                        </Routes>
                    </Container>
                </APIContext.Provider>
            </div>
        )
    }, [refLoadSuccess, connectState, isLogon, isForceLogout, timestamp, endpoint, language])
}

export { APIContext }
export default Page