import { StringProcessor } from "../functions/ByteProcessor"

import { ORDERBOOK_DEPTH } from "./constants"
import ClientMessageHeader from "./header"
import { JumpPoint, TradeQuantityByMinutes } from "./types"

class ClientResponseContainer {
    public message_header_ : ClientMessageHeader
    public view_ : DataView

    public constructor (buffer: ArrayBuffer) {
        this.view_ = new DataView(buffer)

        this.message_header_ = {
            message_type_: this.view_.getUint32(0, true),
            message_bytes_: this.view_.getUint32(4, true)
        }
    }
}

abstract class ClientResponse {
    private container_: ClientResponseContainer
    private readed_bytes_: number

    protected constructor (container: ClientResponseContainer) {
        this.container_ = container
        this.readed_bytes_ = 8
    }

    protected getBoolean(){
        this.readed_bytes_ += 1
        return this.container_.view_.getUint8(this.readed_bytes_ - 1) > 0
    }

    protected getUInt32() {
        this.readed_bytes_ += 4
        return this.container_.view_.getUint32(this.readed_bytes_ - 4, true)
    }

    protected getUInt64() {
        this.readed_bytes_ += 8
        return this.container_.view_.getBigUint64(this.readed_bytes_ - 8, true)
    }

    protected getString(size: number){
        this.readed_bytes_ += size
        return StringProcessor.ByteParser(this.container_.view_, this.readed_bytes_ - size, size)
    }

    protected getJumpPoint(): JumpPoint{
        const timestamp_ = this.getUInt64()

        const u_bid_ = this.getUInt32()
        const u_ask_ = this.getUInt32()
        const u_ask_size_ = this.getUInt32()
        const u_bid_size_ = this.getUInt32()

        const u_prev_bid_ = this.getUInt32()
        const u_prev_ask_ = this.getUInt32()
        const u_prev_bid_size_ = this.getUInt32()
        const u_prev_ask_size_ = this.getUInt32()

        const d_bid_ = this.getUInt32()
        const d_ask_ = this.getUInt32()
        const d_prev_bid_ = this.getUInt32()
        const d_prev_ask_ = this.getUInt32()

        const jumppoint : JumpPoint = {
            timestamp_: timestamp_,

            u_bid_: u_bid_,
            u_ask_: u_ask_,
            u_ask_size_: u_ask_size_,
            u_bid_size_: u_bid_size_,

            u_prev_bid_: u_prev_bid_,
            u_prev_ask_: u_prev_ask_,
            u_prev_bid_size_: u_prev_bid_size_,
            u_prev_ask_size_: u_prev_ask_size_,

            d_bid_: d_bid_,
            d_ask_: d_ask_,
            d_prev_bid_: d_prev_bid_,
            d_prev_ask_: d_prev_ask_
        }

        return jumppoint
    }

    protected getTradeQuantityByMinutes(): TradeQuantityByMinutes {
        const timestamp_ = this.getUInt64()
        const trade_quantity_bid_ = this.getUInt64()
        const trade_quantity_ask_ = this.getUInt64()

        return {
            timestamp_: timestamp_,
            buy_quantity_: Number(trade_quantity_bid_),
            sell_quantity_: Number(trade_quantity_ask_)
        }
    }
}

class ClientLogonResponse extends ClientResponse {
    public success_    : boolean
    public uuid_       : string

    public constructor (container: ClientResponseContainer){
        super(container)

        this.success_ = this.getBoolean()
        if (!this.success_) {
            this.uuid_ = ""
            return;
        }

        this.uuid_ = this.getString(36)
    }
}

class ClientLogoutResponse extends ClientResponse {
    public force_logout_ : boolean

    public constructor (container: ClientResponseContainer){
        super(container)

        this.force_logout_ = this.getBoolean()
    }
}

class ClientLogonCheckResponse extends ClientResponse {
    public success_ : boolean

    public constructor (container: ClientResponseContainer){
        super(container)

        this.success_ = this.getBoolean()
    }
}

//====================
// Retrieve
//====================

class ClientRetrieveJumpPointResponse extends ClientResponse {
    public symbol_: number
    public jump_point_length_: number
    public jump_points_: JumpPoint[]

    public constructor (container: ClientResponseContainer){
        super(container)

        this.symbol_ = this.getUInt32()
        this.jump_point_length_ = this.getUInt32()
        this.jump_points_ = []

        for (let i = 0; i < this.jump_point_length_; i++) this.jump_points_.push(this.getJumpPoint())
    }
}

class ClientRetrieveTradeQuantityByMinutesResponse extends ClientResponse {
    public symbol_: number
    public length_: number
    public trade_quantity_by_minutes_: TradeQuantityByMinutes[]

    public constructor (container: ClientResponseContainer){
        super(container)

        this.symbol_ = this.getUInt32()
        this.length_ = this.getUInt32()
        this.trade_quantity_by_minutes_ = []

        for (let i = 0; i < this.length_; i++) this.trade_quantity_by_minutes_.push(this.getTradeQuantityByMinutes())
    }
}

//====================
// Type of Data
//====================

class ClientQuoteResponse extends ClientResponse {
    public timestamp_ : bigint
    public symbol_: number
    public best_bid_: number
    public best_ask_: number
    public bid_size_: number[]
    public ask_size_: number[]
    public accumalte_bid_quantity_: number
    public accumalte_ask_quantity_: number

    public constructor (container: ClientResponseContainer){
        super(container)

        this.timestamp_ = this.getUInt64()
        this.symbol_ = this.getUInt32()
        this.best_bid_ = this.getUInt32()
        this.best_ask_ = this.getUInt32()

        this.bid_size_ = []
        for (let i = 0; i < ORDERBOOK_DEPTH; i++) this.bid_size_.push(this.getUInt32())
        this.ask_size_ = []
        for (let i = 0; i < ORDERBOOK_DEPTH; i++) this.ask_size_.push(this.getUInt32())

        this.accumalte_bid_quantity_ = this.getUInt32()
        this.accumalte_ask_quantity_ = this.getUInt32()
    }
}

class ClientTradeQuantityByMinutesResponse extends ClientResponse {
    public symbol_: number
    public trade_quantity_by_minutes_: TradeQuantityByMinutes

    public constructor (container: ClientResponseContainer){
        super(container)

        this.symbol_ = this.getUInt32()
        this.trade_quantity_by_minutes_ = this.getTradeQuantityByMinutes()
    }
}

export {
    // Response Container
    ClientResponseContainer,

    // Response
    ClientLogonResponse, ClientLogonCheckResponse, ClientLogoutResponse,

    // Retrieve
    ClientRetrieveJumpPointResponse, ClientRetrieveTradeQuantityByMinutesResponse,

    // Type of Data
    ClientQuoteResponse, ClientTradeQuantityByMinutesResponse,
}