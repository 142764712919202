export enum Language {
    en = 0,
    zhTC = 1,
    zhSC = 2
}

export interface TextMap {
    component: {
        topnav : {
            name: string
            home: string
            tutorial: string
            login: string
            logout: string
        }
        option_list: {
            name: string
            no_underlying_selected: string
            no_option_found: string
            maxSpread: string
            filter: string
            dcode: string
            issuer: string
            bid: string
            ask: string
            right: string
            jump: string
            outstanding: string
        }
        jump_point_list: {
            name: string
            no_option_selected: string
            no_jump_point_data_found: string
            no_jump_point_found: string
            no_option_data_found: string
            dcode: string
            ratio: string
            volume: string
            search: string
            time: string
            dbid: string
            dask: string
            ubid: string
            uask: string
            ubidsize: string
            uasksize: string
        }
    }
    page : {
        login: {
            login: string
            username: string
            password: string
            remember_password: string
            wrong_username_or_password: string
            username_password_cannot_be_empty: string
            username_invalid: string
            logout_success: string
            logon_from_another_place: string
        }
        orderbook_screener: {
            control_panel: {
                name: string
                bid: string
                ask: string
                multiplier: string
                levels: string
                orderbook_volume_search: string
                search: string
                ucode: string
                multix: string
                select: string
                screeningUCode: string
                remove: string
                reset: string
                confirmReset: string
                yes: string
                no: string
                supportMode: string
                bestLevelOnly: string
            }
            order_book: {
                search: string
                name: string
                price: string
                size: string
                bid: string
                ask: string
                no_underlying_selected: string
                no_quote_found: string
            }
        },
        trade_quantity_screener: {
            control_panel: {
                name: string
                multiplier: string
                minutes: string
                trade_quantity_search: string
                search: string
                ucode: string
                multix: string
                select: string
                hit_bid: string
                hit_ask: string
                total: string
                screeningUCode: string
                remove: string
                reset: string
                confirmReset: string
                yes: string
                no: string
            }
            trade_quantity_list: {
                name: string
                search: string
                time: string
                hit_ask: string
                hit_bid: string
                no_underlying_selected: string
            }
        }
        tutorial : {
            main: {
                title : string
                usage : string
                menu: string
                menuList: {
                    home: string
                    login: string
                    orderbook_screener: string
                }
            }
            login : {
                title : string
                login_form_: string
                login_form_step1_: string
                login_form_step2_: string
                login_form_step3_: string
            },
            orderbook_screener : {
                title: string
                main: string
                control_panel: string
                order_book: string
                option_list: string
                jump_point_list: string
            }
        }
    }
}

const LanguageMap : Map<Language, TextMap> = new Map<Language, TextMap>([
    [
        Language.en,
        {
            component: {
                topnav : {
                    name: "Warrant Screener",
                    home: "Home",
                    tutorial: "Tutorial",
                    login: "Login",
                    logout: "Logout"
                },
                option_list: {
                    name: "Option List",
                    no_underlying_selected: "No Underlying Selected",
                    no_option_found: "No Option Found",
                    maxSpread: "Max.Spread",
                    filter: "Filter",
                    dcode: "DCode",
                    issuer: "Issuer",
                    bid: "Bid",
                    ask: "Ask",
                    right: "Right",
                    jump: "Jump",
                    outstanding: "Outstanding"
                },
                jump_point_list: {
                    name: "Jump Point List",
                    no_option_selected: "No Option Selected",
                    no_jump_point_data_found: "No JumpPoint Data Found",
                    no_jump_point_found: "No JumpPoint Found",
                    no_option_data_found: "No Option Data Found",
                    dcode: "D",
                    ratio: "Ratio",
                    volume: "Volume",
                    search: "Search",
                    time: "Time",
                    dbid: "DBid",
                    dask: "DAsk",
                    ubid: "UBid",
                    uask: "UAsk",
                    ubidsize: "UBidSize",
                    uasksize: "UAskSize"
                }
            },
            page: {
                login: {
                    login: "Login",
                    username: "Username",
                    password: "Password",
                    remember_password: "Remember Password",
                    wrong_username_or_password: "Wrong Username or Password.",
                    username_password_cannot_be_empty: "Username and Password can't be empty.",
                    username_invalid: "Username is invalid.",
                    logout_success: "Logout Successful.",
                    logon_from_another_place: "Logon from another place."
                },
                orderbook_screener: {
                    control_panel: {
                        name: "Control Panel",
                        bid: "Bid",
                        ask: "Ask",
                        multiplier: "Multiplier",
                        levels: "Levels",
                        orderbook_volume_search: "OrderBook Volume Search",
                        search: "Search",
                        ucode: "UCode",
                        multix: "Multi(x)",
                        select: "Select",
                        screeningUCode: "Screening UCode",
                        remove: "Remove",
                        reset: "Reset",
                        confirmReset: "Confirm reset?",
                        yes: "Yes",
                        no: "No",
                        supportMode: "Support Mode",
                        bestLevelOnly: "Best Level Only"
                    },
                    order_book: {
                        search: "Search",
                        name: "OrderBook",
                        price: "Price",
                        size: "Size",
                        bid: "Bid",
                        ask: "Ask",
                        no_underlying_selected: "No Underlying Selected",
                        no_quote_found: "No Quotation Found"
                    }
                },
                trade_quantity_screener: {
                    control_panel: {
                        name: "Control Panel",
                        multiplier: "Multiplier",
                        minutes: "Minutes",
                        trade_quantity_search: "Trade Quantity Search",
                        search: "Search",
                        ucode: "UCode",
                        multix: "Multi(x)",
                        select: "Select",
                        hit_bid: "Hit Bid",
                        hit_ask: "Hit Ask",
                        total: "Total",
                        screeningUCode: "Screening UCode",
                        remove: "Remove",
                        reset: "Reset",
                        confirmReset: "Confirm reset?",
                        yes: "Yes",
                        no: "No"
                    },
                    trade_quantity_list: {
                        name: "Trade Quantity List",
                        search: "Search",
                        time: "Time",
                        hit_ask: "Hit Ask",
                        hit_bid: "Hit Bid",
                        no_underlying_selected: "No Underlying Selected"
                    }
                },
                tutorial: {
                    main: {
                        title: "Welcome to Tutorial Page.",
                        usage: "You can click on the feature you want to use from the menu, and we will show you how to use it.",
                        menu: "Tutorial Menu",
                        menuList : {
                            home: "Tutorial Home",
                            login: "Login",
                            orderbook_screener: "Orderbook Screener"
                        }
                    },
                    login: {
                        title: "Login Page",
                        login_form_: "Login Form",
                        login_form_step1_: "Type in your account and password.",
                        login_form_step2_: "If you want to remember the password on the browser, turn on the \"Remember Password\" switch.",
                        login_form_step3_: "Click \"Login\" Button."
                    },
                    orderbook_screener : {
                        title: "Orderbook Screener Page",
                        main: "Orderbook Screener",
                        control_panel: "Control Panel",
                        order_book: "OrderBook",
                        option_list: "Option List",
                        jump_point_list: "JumpPoint List"
                    }
                }
            }
        }
    ],
    [
        Language.zhTC,
        {
            component: {
                topnav : {
                    name: "窩輪監視器",
                    home: "主頁",
                    tutorial: "教學",
                    login: "登入",
                    logout: "登出"
                },
                option_list: {
                    name: "窩輪列表",
                    no_underlying_selected: "未選擇正股",
                    no_option_found: "未找到窩輪/牛熊",
                    maxSpread: "最大間隔",
                    filter: "過濾",
                    dcode: "窩輪代碼",
                    issuer: "發行商",
                    bid: "買",
                    ask: "沽",
                    right: "類型",
                    jump: "敏感度",
                    outstanding: "街貨"
                },
                jump_point_list: {
                    name: "轉價位列表",
                    no_option_selected: "未選擇窩輪/牛熊",
                    no_jump_point_data_found: "未找到轉價位資料",
                    no_jump_point_found: "未找到轉價位",
                    no_option_data_found: "找不到該窩輪/牛熊",
                    dcode: "D",
                    ratio: "比例",
                    volume: "貨量",
                    search: "搜尋",
                    time: "時間",
                    dbid: "買",
                    dask: "沽",
                    ubid: "正股買",
                    uask: "正股沽",
                    ubidsize: "正股買方貨量",
                    uasksize: "正股沽方貨量"
                }
            },
            page: {
                login: {
                    login: "登入",
                    username: "帳號",
                    password: "密碼",
                    remember_password: "記住密碼",
                    wrong_username_or_password: "錯誤的帳號或密碼。",
                    username_password_cannot_be_empty: "帳號及密碼不可留空。",
                    username_invalid: "帳號格式不正確。",
                    logout_success: "登出成功。",
                    logon_from_another_place: "你已在其他地方登入。"
                },
                orderbook_screener: {
                    control_panel: {
                        name: "控制面板",
                        bid: "買",
                        ask: "沽",
                        multiplier: "倍率",
                        levels: "層數",
                        orderbook_volume_search: "盤口數據貨量搜尋",
                        search: "搜尋",
                        ucode: "正股代碼",
                        multix: "倍率(x)",
                        select: "選擇",
                        screeningUCode: "監測正股",
                        remove: "移除",
                        reset: "重置",
                        confirmReset: "確定重置？",
                        yes: "是",
                        no: "否",
                        supportMode: "支撐模式",
                        bestLevelOnly: "僅比較最佳層"
                    },
                    order_book: {
                        search: "搜尋",
                        name: "盤口數據",
                        price: "價位",
                        size: "貨量",
                        bid: "買",
                        ask: "沽",
                        no_underlying_selected: "未選擇正股",
                        no_quote_found: "找不到盤口數據"
                    }
                },
                trade_quantity_screener: {
                    control_panel: {
                        name: "控制面板",
                        multiplier: "倍率",
                        minutes: "分鐘",
                        trade_quantity_search: "交易量搜尋",
                        search: "搜尋",
                        ucode: "正股代碼",
                        multix: "倍率(x)",
                        select: "選擇",
                        hit_bid: "主動沽",
                        hit_ask: "主動買",
                        total: "總和",
                        screeningUCode: "監測正股",
                        remove: "移除",
                        reset: "重置",
                        confirmReset: "確定重置？",
                        yes: "是",
                        no: "否"
                    },
                    trade_quantity_list: {
                        name: "交易量列表",
                        search: "搜尋",
                        time: "時間",
                        hit_ask: "主動買",
                        hit_bid: "主動沽",
                        no_underlying_selected: "未選擇正股"
                    }
                },
                tutorial: {
                    main: {
                        title: "歡迎來到教學頁面",
                        usage: "請點擊列表上你想查看的功能，我們會為你展示它的使用方法。",
                        menu: "教學列表",
                        menuList : {
                            home: "教學主頁",
                            login: "登入",
                            orderbook_screener: "盤口數據監視器"
                        }
                    },
                    login: {
                        title: "登入頁面",
                        login_form_: "登入表格",
                        login_form_step1_: "輸入你的帳號及密碼。",
                        login_form_step2_: "如果你想將密碼保存在瀏覽器中，打開\"Remember Password\"。",
                        login_form_step3_: "點擊\"Login\"按鈕。"
                    },
                    orderbook_screener : {
                        title: "盤口數據監視器頁面",
                        main: "盤口數據監視器",
                        control_panel: "控制面板",
                        order_book: "盤口數據",
                        option_list: "窩輪列表",
                        jump_point_list: "轉價位列表"
                    }
                }
            }
        }
    ],
    [
        Language.zhSC,
        {
            component: {
                topnav : {
                    name: "窝轮监视器",
                    home: "主页",
                    tutorial: "教学",
                    login: "登入",
                    logout: "登出"
                },
                option_list: {
                    name: "窝轮列表",
                    no_underlying_selected: "未选择正股",
                    no_option_found: "未找到窝轮/牛熊",
                    maxSpread: "最大间隔",
                    filter: "过滤",
                    dcode: "窝轮代码",
                    issuer: "发行商",
                    bid: "买",
                    ask: "沽",
                    right: "类型",
                    jump: "敏感度",
                    outstanding: "街货"
                },
                jump_point_list: {
                    name: "转价位列表",
                    no_option_selected: "未选择窝轮/牛熊",
                    no_jump_point_data_found: "未找到转价位资料",
                    no_jump_point_found: "未找到转价位",
                    no_option_data_found: "找不到该窝轮/牛熊",
                    dcode: "D",
                    ratio: "比例",
                    volume: "货量",
                    search: "搜索",
                    time: "时间",
                    dbid: "买",
                    dask: "沽",
                    ubid: "正股买",
                    uask: "正股沽",
                    ubidsize: "正股买方货量",
                    uasksize: "正股沽方货量"
                }
            },
            page: {
                login: {
                    login: "登入",
                    username: "帐号",
                    password: "密码",
                    remember_password: "保存密码",
                    wrong_username_or_password: "错误的帐号或密码。",
                    username_password_cannot_be_empty: "帐号和密码不可留空。",
                    username_invalid: "帐号格式不正确。",
                    logout_success: "登出成功。",
                    logon_from_another_place: "你已在其他地方登入。"
                },
                orderbook_screener: {
                    control_panel: {
                        name: "控制面板",
                        bid: "买",
                        ask: "沽",
                        multiplier: "倍率",
                        levels: "层数",
                        orderbook_volume_search: "盘口数据货量搜索",
                        search: "搜索",
                        ucode: "正股代码",
                        multix: "倍率(x)",
                        select: "选择",
                        screeningUCode: "监测正股",
                        remove: "删除",
                        reset: "重置",
                        confirmReset: "确定重置？",
                        yes: "是",
                        no: "否",
                        supportMode: "支撑模式",
                        bestLevelOnly: "仅比较最佳层"
                    },
                    order_book: {
                        search: "搜索",
                        name: "盘口数据",
                        price: "价格",
                        size: "货量",
                        bid: "买",
                        ask: "沽",
                        no_underlying_selected: "未选择正股",
                        no_quote_found: "找不到盘口数据"
                    }
                },
                trade_quantity_screener: {
                    control_panel: {
                        name: "控制面板",
                        multiplier: "倍率",
                        minutes: "分钟",
                        trade_quantity_search: "交易量搜索",
                        search: "搜索",
                        ucode: "正股代码",
                        multix: "倍率(x)",
                        select: "选择",
                        hit_bid: "主动沽",
                        hit_ask: "主动买",
                        total: "总和",
                        screeningUCode: "监测正股",
                        remove: "删除",
                        reset: "重置",
                        confirmReset: "确定重置？",
                        yes: "是",
                        no: "否",
                    },
                    trade_quantity_list: {
                        name: "交易量列表",
                        search: "搜索",
                        time: "时间",
                        hit_ask: "主动买",
                        hit_bid: "主动沽",
                        no_underlying_selected: "未选择正股"
                    }
                },
                tutorial: {
                    main: {
                        title: "欢迎来到教学页面",
                        usage: "请选择选单上你想查看的功能，我们会为你展示它的使用方式。",
                        menu: "教学列表",
                        menuList : {
                            home: "教学主页",
                            login: "登入",
                            orderbook_screener: "盘口数据监视器"
                        }
                    },
                    login: {
                        title: "登入页面",
                        login_form_: "登入表单",
                        login_form_step1_: "输入你的帐号和密码。",
                        login_form_step2_: "如果你想把密码保存在浏览器中，打开\"Remember Password\"。",
                        login_form_step3_: "点击\"Login\"按钮。"
                    },
                    orderbook_screener : {
                        title: "盘口数据监视器页面",
                        main: "盘口数据监视器",
                        control_panel: "控制面板",
                        order_book: "盘口数据",
                        option_list: "窝轮列表",
                        jump_point_list: "转价位列表"
                    }
                }
            }
        }
    ]
])

export { LanguageMap }