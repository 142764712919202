import ClientMessageHeader from "./header"
import { ClientMessageType, SubscribeInteval } from "./enum"

import { StringProcessor } from "../functions/ByteProcessor"

abstract class ClientRequest {
    message_header_ : ClientMessageHeader

    constructor(header: ClientMessageHeader) {
        this.message_header_ = header
    }

    allocateBuffer() {
        const arraybuf = new ArrayBuffer(this.message_header_.message_bytes_)
        const view = new DataView(arraybuf)

        view.setUint32(0, this.message_header_.message_type_, true)
        view.setUint32(4, this.message_header_.message_bytes_, true)

        return {
            arraybuf: arraybuf,
            view: view
        }
    }

    abstract toArrayBuffer() : ArrayBuffer;
}

class ClientPingRequest extends ClientRequest {
    constructor(){
        super({
            message_type_: ClientMessageType.kPing,
            message_bytes_: 8
        })
    }

    toArrayBuffer(): ArrayBuffer {
        return this.allocateBuffer().arraybuf
    }
}

class ClientLogonRequest extends ClientRequest {
    username_: string
    password_: string

    constructor(username: string, password: string) {
        super({
            message_type_: ClientMessageType.kLogon,
            message_bytes_: 8 + 32 + 64
        })

        this.username_ = username
        this.password_ = password
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8;

        StringProcessor.StringCopyer(view, this.username_, pos, 32); pos += 32;
        StringProcessor.StringCopyer(view, this.password_, pos, 64); pos += 64;

        return arraybuf
    }
}

class ClientLogonCheckRequest extends ClientRequest {
    uuid_: string

    constructor(uuid: string) {
        super({
            message_type_: ClientMessageType.kLogonCheck,
            message_bytes_: 8 + 36
        })

        this.uuid_ = uuid
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8

        StringProcessor.StringCopyer(view, this.uuid_, pos, 36); pos += 36;

        return arraybuf
    }
}

class ClientLogoutRequest extends ClientRequest {
    uuid_: string

    constructor(uuid: string) {
        super({
            message_type_: ClientMessageType.kLogout,
            message_bytes_: 8 + 36
        })

        this.uuid_ = uuid
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8

        StringProcessor.StringCopyer(view, this.uuid_, pos, 36); pos += 36;

        return arraybuf
    }
}

class ClientSubscribeQuoteRequest extends ClientRequest {
    symbol_: number
    inteval_: SubscribeInteval
    force_send_: boolean

    constructor(symbol: number, interval: SubscribeInteval, force_send: boolean) {
        super({
            message_type_: ClientMessageType.kSubscribeQuote,
            message_bytes_: 8 + 6
        })

        this.symbol_ = symbol
        this.inteval_ = interval
        this.force_send_ = force_send
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8

        view.setUint32(pos, this.symbol_, true); pos += 4
        view.setUint8(pos, this.inteval_); pos += 1
        view.setUint8(pos, this.force_send_?1:0); pos += 1

        return arraybuf
    }
}

class ClientSubscribeTradeQuantityByMinutesRequest extends ClientRequest {
    symbol_: number
    inteval_: SubscribeInteval
    force_send_: boolean

    constructor(symbol: number, interval: SubscribeInteval, force_send: boolean) {
        super({
            message_type_: ClientMessageType.kSubscribeTradeQauntityByMinutes,
            message_bytes_: 8 + 6
        })

        this.symbol_ = symbol
        this.inteval_ = interval
        this.force_send_ = force_send
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8

        view.setUint32(pos, this.symbol_, true); pos += 4
        view.setUint8(pos, this.inteval_); pos += 1
        view.setUint8(pos, this.force_send_?1:0); pos += 1

        return arraybuf
    }
}

class ClientCleanSubscriptionRequest extends ClientRequest {
    constructor(){
        super({
            message_type_: ClientMessageType.kCleanSubscription,
            message_bytes_: 8
        })
    }

    toArrayBuffer(): ArrayBuffer {
        return this.allocateBuffer().arraybuf
    }
}

class ClientRetrieveJumpPointRequest extends ClientRequest {
    symbol_: number
    constructor(symbol: number) {
        super({
            message_type_: ClientMessageType.kRetrieveJumpPoint,
            message_bytes_: 8 + 4
        })

        this.symbol_ = symbol
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8

        view.setUint32(pos, this.symbol_, true); pos += 4

        return arraybuf
    }
}

class ClientRetrieveTradeQuantityByMinutesRequest extends ClientRequest {
    symbol_: number
    constructor(symbol: number) {
        super({
            message_type_: ClientMessageType.kRetrieveTradeQuantityByMinutes,
            message_bytes_: 8 + 4
        })

        this.symbol_ = symbol
    }

    toArrayBuffer(): ArrayBuffer {
        const { arraybuf, view } = this.allocateBuffer()
        let pos = 8

        view.setUint32(pos, this.symbol_, true); pos += 4

        return arraybuf
    }
}

export {
    // Request Container
    ClientRequest,

    // Basic Request
    ClientPingRequest, ClientLogonRequest, ClientLogonCheckRequest, ClientLogoutRequest,

    // Subscription
    ClientSubscribeQuoteRequest, ClientCleanSubscriptionRequest, ClientSubscribeTradeQuantityByMinutesRequest,

    // Retrieve
    ClientRetrieveJumpPointRequest, ClientRetrieveTradeQuantityByMinutesRequest
}