// function
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

// private function
import { OptionSide, OptionType, Side, SubscribeInteval } from "../api/enum"
import { ClientSubscribeQuoteRequest } from "../api/request"
import { ClientQuoteResponse } from "../api/response"
import { OptionInfo, U2OptionInfos } from "../functions/OptionInfo"
import { GetSpread, GetSpreadTableType, SpreadBetween } from "../functions/SpreadTable"
import { LanguageMap } from "../functions/Language"
import { APIContext } from "../pages"

// ui component
import { Button, Card, Form, InputGroup, Table } from "react-bootstrap"

const DEAFULT_MAXSPREAD = 3

export interface OptionListRowProps {
    u_quote: ClientQuoteResponse | null
    quote: ClientQuoteResponse | undefined
    optionInfo: OptionInfo
    setSelectedOption : React.Dispatch<React.SetStateAction<number|null>>
}

function OptionListRow({u_quote, quote, optionInfo, setSelectedOption} : OptionListRowProps) {
    return useMemo(()=>{
        const bid = quote?(quote.best_bid_/1000).toFixed(3):"N/A"
        const ask = quote?(quote.best_ask_/1000).toFixed(3):"N/A"
        const right_class = "text-white " + (optionInfo.option_side_ === OptionSide.kCallorBull ? "bg-success" : "bg-danger")

        const right_name = (
            optionInfo.option_type_ === OptionType.kWarrant?
            (optionInfo.option_side_ === OptionSide.kCallorBull ? "CALL" : "PUT"):
            (optionInfo.option_side_ === OptionSide.kCallorBull ? "BULL" : "BEAR")
        )
        const jump = (
            !quote || !u_quote ? "N/A" : (
                optionInfo.option_type_ === OptionType.kWarrant?
                ((optionInfo.delta / 100 * GetSpread(u_quote.best_bid_, GetSpreadTableType(u_quote.symbol_)) / 1000) / (GetSpread(quote.best_bid_, GetSpreadTableType(optionInfo.symbol_)) / 1000 * optionInfo.cratio)).toFixed(3):
                ((GetSpread(u_quote.best_bid_, GetSpreadTableType(u_quote.symbol_)) / 1000) / (GetSpread(quote.best_bid_, GetSpreadTableType(optionInfo.symbol_)) / 1000 * optionInfo.cratio)).toFixed(3)
            )
        )

        return (
            <tr>
                <td style={{cursor: "pointer"}} onClick={() => setSelectedOption(optionInfo.symbol_)}>
                    {optionInfo.symbol_}
                </td>
                <td style={{textAlign: "center"}}>{optionInfo.issuer}</td>
                <td style={{textAlign: "center"}} className={right_class}>{right_name}</td>
                <td style={{textAlign: "center"}}>{bid}</td>
                <td style={{textAlign: "center"}}>{ask}</td>
                <td style={{textAlign: "right"}}>{jump}</td>
                <td style={{textAlign: "right"}}>{optionInfo.outstandingRatio}%</td>
            </tr>
        )
    }, [u_quote, quote, optionInfo, setSelectedOption])
}

export interface OptionListProps {
    quotes: {[key:string]: ClientQuoteResponse|undefined }
    underlying: number | null
    side: Side | null
    setSelectedOption : React.Dispatch<React.SetStateAction<number|null>>
}

function OptionList({ quotes, underlying, side, setSelectedOption } : OptionListProps){
    const { timestamp, endpoint, language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.component.option_list, [language])

    const [maxSpread, setMaxSpread] = useState(DEAFULT_MAXSPREAD)

    const u_quote = useMemo(()=>{
        if (underlying === null) return null
        if (Object.keys(quotes).indexOf(underlying.toString()) === -1) return null
        return quotes[underlying.toString()]!
    }, [quotes, underlying])

    const QuoteRequestEpoch = useRef<number>(-1)
    const optionInfos = useMemo(()=>{
        if (underlying === null || !U2OptionInfos.has(underlying)) return null

        const optionInfos = U2OptionInfos.get(underlying)!

        const list = side===null?[...optionInfos.call, ...optionInfos.put]:optionInfos[side === Side.kBid?"put":"call"]
        QuoteRequestEpoch.current = -1 // reset for getting new quote

        return list.length === 0 ? null : list
    }, [underlying, side])

    useEffect(()=>{
        if (optionInfos == null) return

        if (timestamp - QuoteRequestEpoch.current > 15000) {
            QuoteRequestEpoch.current = timestamp

            const req = new ClientSubscribeQuoteRequest(0, SubscribeInteval.kOnce, true)
            optionInfos.forEach((x)=>{
                req.symbol_ = x.symbol_
                endpoint.send(req)
            })
        }
    }, [endpoint, timestamp, optionInfos])

    const filteredOptions : OptionInfo[] | null = useMemo(()=>{
        if (optionInfos === null || optionInfos.length === 0) return null
        
        const list = optionInfos.filter((x)=>{
            if (typeof(quotes[x.symbol_]) === "undefined") return false // filter no quote
            const quote = quotes[x.symbol_]!
            if (quote.best_bid_ < 20) return false // filter bid < 0.020
            if (SpreadBetween(quote.best_bid_, quote.best_ask_, GetSpreadTableType(quote.symbol_)) > maxSpread) return false
            return true
        }).map((x)=>{
            const quote = quotes[x.symbol_]
            const jump = quote&&u_quote?(
                x.option_type_===OptionType.kWarrant?
                ( (x.delta / 100 * GetSpread(u_quote.best_bid_, GetSpreadTableType(u_quote.symbol_)) / 1000) / (GetSpread(quote.best_ask_, GetSpreadTableType(x.symbol_)) / 1000 * x.cratio) ):
                ( (GetSpread(u_quote.best_bid_, GetSpreadTableType(u_quote.symbol_)) / 1000) / (GetSpread(quote.best_ask_, GetSpreadTableType(x.symbol_)) / 1000 * x.cratio) )
            ):-1

            return { x, jump }
        }).sort((a,b)=>b.jump - a.jump).map(x=>x.x)

        return list.length > 0 ? list : null
    }, [optionInfos, u_quote, quotes, maxSpread])

    return useMemo(()=>{
        return (
            <Card style={{flex: 4, minWidth: 300, flexShrink: 0}}>
                <Card.Header>{textMap.name}{underlying===null?"":(" - " + underlying.toString())}</Card.Header>
                <Card.Body style={{display: "flex", flexDirection: "column", gap: "0.5rem", padding: "0.75rem", minHeight: "28.75rem", maxHeight: "28.75rem"}}>
                    <Form style={{display: "flex", flexDirection: "row", gap: "0.5rem"}} onSubmit={(event)=>{
                        event.preventDefault()
                        event.stopPropagation()
                        const formData = new FormData(event.currentTarget)
                        const maxSpread = parseInt(formData.get("maxSpread")!.toString())

                        setMaxSpread(maxSpread)
                    }}>
                        <InputGroup style={{maxWidth: 200}} size="sm">
                            <InputGroup.Text>{textMap.maxSpread}</InputGroup.Text>
                            <Form.Control type="number" name="maxSpread" min="1" max="20" size="sm" defaultValue={DEAFULT_MAXSPREAD}/>
                        </InputGroup>
                        <Button type="submit" size="sm">{textMap.filter}</Button>
                    </Form>
                    {
                        underlying === null ? <Card.Text>{textMap.no_underlying_selected}</Card.Text> :
                        filteredOptions === null ? <Card.Text>{textMap.no_option_found}</Card.Text> :
                        <div style={{height: "100%", overflowY: "auto"}}>
                            <Table bordered hover size="sm" style={{marginBottom: 0}}>
                                <thead className="bg-light sticky-top top-0" style={{boxShadow: "0 -1px 0 #dee2e6, inset 0 -1px 5px #dee2e6"}}>
                                    <tr>
                                        <th style={{textAlign: "center"}}>{textMap.dcode}</th>
                                        <th style={{textAlign: "center"}}>{textMap.issuer}</th>
                                        <th style={{textAlign: "center"}}>{textMap.right}</th>
                                        <th style={{textAlign: "center"}}>{textMap.bid}</th>
                                        <th style={{textAlign: "center"}}>{textMap.ask}</th>
                                        <th style={{textAlign: "center"}}>{textMap.jump}</th>
                                        <th style={{textAlign: "center"}}>{textMap.outstanding}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredOptions.map((v)=>{
                                            return (
                                                <OptionListRow key={v.symbol_}
                                                    u_quote={u_quote} quote={quotes[v.symbol_]} optionInfo={v} setSelectedOption={setSelectedOption}
                                                />
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    }
                </Card.Body>
            </Card>
        )
    }, [textMap, quotes, u_quote, underlying, setSelectedOption, filteredOptions])
}

export default OptionList