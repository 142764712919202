// function
import { useContext, useMemo } from "react"
import { Route, Routes } from "react-router-dom"

// private function
import { APIContext } from ".."
import { LanguageMap } from "../../functions/Language"

// ui component
import { Container, ListGroup, Row, Col, Card } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"

// private component
import LoginTutorial from "./component/Login"
import OrderbookScreenerTutorial from "./component/OrderbookScreener"

function TutorialHome() {
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.tutorial.main, [language])

    return useMemo(()=>{
        return (
            <Card className="shadow">
                <Card.Header>{textMap.title}</Card.Header>
                <Card.Body>
                    <Card.Text>{textMap.usage}</Card.Text>
                </Card.Body>
            </Card>
        )
    }, [textMap])
}

function TutorialPage(){
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.tutorial.main, [language])

    return useMemo(()=>{
        return (
            <Container>
                <Row>
                    <Col style={{marginBottom: 16}}>
                        <h3>{textMap.menu}</h3>
                        <ListGroup as="ul">
                            <LinkContainer to=""><ListGroup.Item as="a">{textMap.menuList.home}</ListGroup.Item></LinkContainer>
                            <LinkContainer to="login"><ListGroup.Item as="a">{textMap.menuList.login}</ListGroup.Item></LinkContainer>
                            <LinkContainer to="orderbookscreener"><ListGroup.Item as="a">{textMap.menuList.orderbook_screener}</ListGroup.Item></LinkContainer>
                        </ListGroup>
                    </Col>
                    <Col md={9} style={{maxWidth: "100%"}}>
                        <Routes>
                            <Route path="login" element={<LoginTutorial />} />
                            <Route path="orderbookscreener" element={<OrderbookScreenerTutorial />} />
                            <Route path="" element={<TutorialHome />} />
                            <Route path="*" element={<></>} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        )
    }, [textMap])
}

export default TutorialPage