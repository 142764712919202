import { ClientMessageType } from "./enum"
import { ClientRequest, ClientLogonCheckRequest, ClientPingRequest } from "./request"
import { ClientResponseContainer, ClientLogonResponse, ClientLogonCheckResponse, ClientLogoutResponse } from "./response"

class Endpoint {
    private websocket?: WebSocket
    private pinger?: NodeJS.Timer
    public on_message: (c: ClientResponseContainer) => void

    constructor () {
        this.on_message = () => {}
    }

    connect(
        setConnectState: React.Dispatch<React.SetStateAction<boolean|number|null>>,
        setIsLogin: React.Dispatch<React.SetStateAction<boolean|null>>,
        setIsForceLogout: React.Dispatch<React.SetStateAction<boolean|null>>
    ) {
        if (this.websocket) {
            if (this.websocket.readyState === WebSocket.CONNECTING) return
            if (this.websocket.readyState === WebSocket.OPEN) return
        }

        const isDebug = window.location.protocol!=="https:"
        const ws_endpoint = "wss://" + (isDebug ? "warrant-screener.a-ticks.com" : window.location.hostname ) + "/" + (isDebug ? "tws" : "ws")
        this.websocket = new WebSocket(ws_endpoint);
        this.websocket.binaryType = "arraybuffer"

        // setup logon check
        this.websocket.onopen = () => {
            console.log("connected at " + new Date().getTime())
            setConnectState(true)

            if (!this.pinger){
                this.pinger = setInterval(()=>{
                    if (!this.websocket) return
                    if (this.websocket.readyState !== WebSocket.OPEN) return
                    this.send(new ClientPingRequest())
                }, 5000)
            }

            // check login status
            const userUUID = localStorage.getItem("userUUID")
            if (userUUID !== null) this.send(new ClientLogonCheckRequest(userUUID))
        }
        this.websocket.onclose = () => {
            setConnectState(false)

            if (this.pinger) {
                clearInterval(this.pinger)
                delete(this.pinger)
            }
        }
        this.websocket.onerror = (event) => {
            console.log("[Websocket][Error]", event)
        }

        // setup logon
        this.websocket.onmessage = (event) => {
            if (! (event.data instanceof ArrayBuffer) ) return 

            const container_ = new ClientResponseContainer(event.data)

            switch (container_.message_header_.message_type_) {
                case ClientMessageType.kLogonCheck:
                    const loginCheckResp = new ClientLogonCheckResponse(container_)
                    setIsLogin(loginCheckResp.success_)
                    break;
                case ClientMessageType.kLogon:
                    const loginResp = new ClientLogonResponse(container_)
                    if (loginResp.success_)
                        localStorage.setItem("userUUID", loginResp.uuid_);
                    setIsLogin(loginResp.success_)
                    this.on_message(container_)
                    break;
                case ClientMessageType.kLogout:
                    const logoutResp = new ClientLogoutResponse(container_)
                    setIsForceLogout(logoutResp.force_logout_)
                    setIsLogin(false)
                    break;
                case ClientMessageType.kPing:
                    break;
                default:
                    this.on_message(container_)
                    break;
            }
        }
    }

    send (request: ClientRequest | ClientRequest[]) {
        if (!this.websocket) return
        if (this.websocket.readyState !== this.websocket.OPEN) return;

        // single message
        if (request instanceof ClientRequest) {
            const buf = request.toArrayBuffer()
            this.websocket.send(buf)
            return;
        }

        // multiple messages
        if (request.length === 0) return;
        const buffers = request.map((x)=>x.toArrayBuffer())

        const totalLength = buffers.reduce((p, c)=>p + c.byteLength, 0)
        const buf = new ArrayBuffer(totalLength)
        const view = new DataView(buf)

        let offset = 0
        buffers.forEach((x)=>{
            const v = new DataView(x)
            for (let i = 0; i < x.byteLength; i++){
                view.setUint8(offset, v.getUint8(i))
                offset++
            }
        })
        this.websocket.send(buf)
    }
}

export { Endpoint }