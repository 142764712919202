enum DateStringType{
    hhmmss = 0,
    hhmmss_millisecond = 1,
    hhmmss_microsecond = 2,
    hhmmss_nanosecond = 3
}

function From_Nanosecond_To_DateString(nanosecond_epoch: bigint, type: DateStringType){
    const millisecond_epoch = Number(nanosecond_epoch / BigInt(1000000))
    const date = new Date(millisecond_epoch)

    const hour = ("0" + date.getHours()).slice(-2)
    const minutes = ("0" + date.getMinutes()).slice(-2)
    const second = ("0" + date.getSeconds()).slice(-2)

    if (type === DateStringType.hhmmss) return hour + ":" + minutes + ":" + second

    const nanosecond = ("00000000" + Number(nanosecond_epoch % BigInt(1000000000))).slice(-9)
    const microsecond = nanosecond.slice(0, 6)
    const millisecond = nanosecond.slice(0, 3)

    if (type === DateStringType.hhmmss_millisecond) return hour + ":" + minutes + ":" + second + "." + millisecond
    if (type === DateStringType.hhmmss_microsecond) return hour + ":" + minutes + ":" + second + "." + microsecond
    if (type === DateStringType.hhmmss_nanosecond) return hour + ":" + minutes + ":" + second + "." + nanosecond

    return hour + ":" + minutes + ":" + second + "." + nanosecond
}

export {
    DateStringType,

    From_Nanosecond_To_DateString
}