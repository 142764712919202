// function
import { useEffect, useRef, useCallback, useContext, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"

// private function
import { ClientMessageType, Side, SubscribeInteval } from "../../api/enum";
import { ClientSubscribeQuoteRequest } from "../../api/request";
import { ClientResponseContainer, ClientQuoteResponse, ClientRetrieveJumpPointResponse } from "../../api/response";
import { U2OptionInfos } from "../../functions/OptionInfo";

// private ui component
import ControlPanel from "./component/ControlPanel";
import OrderBook from "./component/OrderBook";
import OptionList from "../../component/OptionList";
import JumpPointList from "../../component/JumpPointList";

// context
import { APIContext } from "..";

function OrderBookScreenerPage() {
    const { isLogon, endpoint } = useContext(APIContext)
    const navigate = useNavigate()

    // raw data
    const quoteRef = useRef<{[key:string]: ClientQuoteResponse|undefined}>({})
    const [quotes, setQuotes] = useState<{[key:string]: ClientQuoteResponse|undefined}>({})
    const jumppointRef = useRef<{[key:string]: ClientRetrieveJumpPointResponse}>({})
    const [jumppoints, setJumpPoints] = useState<{[key:string]: ClientRetrieveJumpPointResponse}>({})

    const onmessage = useCallback((container: ClientResponseContainer)=>{
        if (container.message_header_.message_type_ === ClientMessageType.kQuote){
            const resp = new ClientQuoteResponse(container)
            // console.debug(resp)
            quoteRef.current[resp.symbol_.toString()] = resp
            setQuotes(()=>Object.assign({}, quoteRef.current))
        }
        else if (container.message_header_.message_type_ === ClientMessageType.kRetrieveJumpPoint){
            const resp = new ClientRetrieveJumpPointResponse(container)
            console.debug(resp)
            jumppointRef.current[resp.symbol_] = resp
            setJumpPoints(()=>Object.assign({}, jumppointRef.current))
        }
    }, [])

    const SendSubscribeUnderlyingRequest = useRef<boolean>(false)
    useEffect(() => {
        // back to index if logged out
        if (isLogon === false) {
            navigate("/");
            return
        }

        if (endpoint.on_message !== onmessage) endpoint.on_message = onmessage

        if (SendSubscribeUnderlyingRequest.current === false) {
            SendSubscribeUnderlyingRequest.current = true

            // send multiple subscribe message for each underlying
            endpoint.send(Array.from(U2OptionInfos.keys()).map(u => new ClientSubscribeQuoteRequest(u, SubscribeInteval.k5Second, true)))
        }
    }, [isLogon, endpoint, navigate, onmessage])

    // status
    const [selectedSide, setSelectedSide] = useState<Side|null>(null)
    const [selectedUnderlying, setSelectedUnderlying] = useState<number|null>(null)
    const [selectedOption, setSelectedOption] = useState<number|null>(null)

    const quote_from_selectedUnderlying = useMemo(()=>{
        if (selectedUnderlying === null) return null
        if (Object.keys(quotes).indexOf(selectedUnderlying.toString()) === -1) return null
        return quotes[selectedUnderlying]!
    }, [selectedUnderlying, quotes])

    const jumppoint_from_selectedOption = useMemo(()=>{
        if (selectedOption === null) return null
        if (Object.keys(jumppoints).indexOf(selectedOption.toString()) === -1) return null
        return jumppoints[selectedOption]
    }, [selectedOption, jumppoints])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", gap: 16}}>
                <div style={{flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 16, height: "fit-content"}}>
                    <ControlPanel quotes={quotes} setSelectedUnderlying={setSelectedUnderlying} setSelectedSide={setSelectedSide}/>
                    <OrderBook quote={quote_from_selectedUnderlying} selectedUnderlying={selectedUnderlying} setSelectedUnderlying={setSelectedUnderlying} setSelectedSide={setSelectedSide}/>
                    <OptionList quotes={quotes} underlying={selectedUnderlying} side={selectedSide} setSelectedOption={setSelectedOption}/>
                </div>
                <div style={{flex: 1}}>
                    <JumpPointList selectedOption={selectedOption} jumppoint={jumppoint_from_selectedOption} setSelectedOption={setSelectedOption}/>
                </div>
            </div>
        )
    }, [quotes, quote_from_selectedUnderlying, jumppoint_from_selectedOption, selectedUnderlying, selectedSide, selectedOption])
}

export default OrderBookScreenerPage