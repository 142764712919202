import { OptionSide, OptionType } from "../api/enum"

export type OptionInfo = {
    symbol_          : number
    option_side_     : OptionSide
    underlying_      : number
    issuer           : string
    expDate          : number
    cratio           : number
    outstandingRatio : number
} & (
    {
        option_type_    : OptionType.kWarrant
        imvol           : number
        delta           : number
    } |
    {
        option_type_    : OptionType.kCBBC
        callValue       : number
        shares          : number
    }
)

const D2OptionInfo = new Map<number, OptionInfo>()
const U2OptionInfos = new Map<number, {call:OptionInfo[], put:OptionInfo[]}>()
const Issuers : string[] = []

function ParseUnderlying(str: string){
    if (str === "HSI") return 88888
    else if (str === "HSCE") return 88889
    else if (str === "HSTECH") return 88890
    else if (str === "SPX") return 89000
    else if (str === "DJI") return 89001
    else if (str === "NDX") return 89002
    return parseInt(str)
}

async function LoadWarrant(url: string = "https://archive.aq-techs.com/csv/warrant_search_results.csv") {
    const rawResp = await fetch(url)
    if (!rawResp.ok || rawResp.status >= 300) return false

    const resp = await rawResp.text()
    const lines = resp.replaceAll("\r", "").split("\n")

    const currentTime = new Date()
	const dateString =  currentTime.getFullYear() + "-" + ("0"+(currentTime.getMonth() + 1)).slice(-2) + "-" + ("0"+currentTime.getDate()).slice(-2)
	const todayTime = new Date(dateString).getTime()

    lines.forEach((line, i)=>{
        const trimedLine = line.trim()
        if (trimedLine.length === 0) return
        if (i === 0){
            console.log(trimedLine.split(","))
            return
        }
        const parts = trimedLine.split(",")

        const symbol = parseInt(parts[1])
        const underlying = ParseUnderlying(parts[3])
        if (isNaN(symbol) || isNaN(underlying)) return

        const optionData : OptionInfo = {
            symbol_: symbol,
            underlying_: underlying,
            issuer: parts[0],
            option_type_: OptionType.kWarrant,
            option_side_:  parts[5].toUpperCase()==="CALL"?OptionSide.kCallorBull:OptionSide.kPutorBear,
            expDate: (new Date(parts[7]).getTime() - todayTime) / (24*60*60*1000),
            cratio: parseFloat(parts[10]),
            outstandingRatio: parseFloat(parts[21]),
            imvol: parseFloat(parts[17]),
            delta: parseFloat(parts[18])
        }

        // push to d dictionary
        D2OptionInfo.set(symbol, optionData)

        // push to u dictionary
        if (!U2OptionInfos.has(underlying))
            U2OptionInfos.set(underlying, {call: [], put: []})
        U2OptionInfos.get(underlying)![optionData.option_side_ === OptionSide.kCallorBull?"call":"put"].push(optionData)

        // update issuer list
        if (Issuers.indexOf(optionData.issuer) === -1)
            Issuers.push(optionData.issuer)
    })

    return true
}

async function LoadCBBC(url: string = "https://archive.aq-techs.com/csv/cbbc_search_results.csv") {
    const rawResp = await fetch(url)
    if (!rawResp.ok || rawResp.status >= 300) return false

    const resp = await rawResp.text()
    const lines = resp.replaceAll("\r", "").split("\n")

    const currentTime = new Date()
	const dateString =  currentTime.getFullYear() + "-" + ("0"+(currentTime.getMonth() + 1)).slice(-2) + "-" + ("0"+currentTime.getDate()).slice(-2)
	const todayTime = new Date(dateString).getTime()

    lines.forEach((line, i)=>{
        const trimedLine = line.trim()
        if (trimedLine.length === 0) return
        if (i === 0){
            console.log(trimedLine.split(","))
            return
        }
        const parts = trimedLine.split(",")

        const symbol = parseInt(parts[1])
        const underlying = ParseUnderlying(parts[3])
        if (isNaN(symbol) || isNaN(underlying)) return

        const optionData : OptionInfo = {
            symbol_: symbol,
            underlying_: underlying,
            issuer: parts[0],
            option_type_: OptionType.kCBBC,
            option_side_:  parts[5].toUpperCase()==="BULL"?OptionSide.kCallorBull:OptionSide.kPutorBear,
            expDate: (new Date(parts[14]).getTime() - todayTime) / (24*60*60*1000),
            cratio: parseFloat(parts[9]),
            outstandingRatio: parseFloat(parts[21]),
            callValue: parseFloat(parts[6]),
            shares: Math.round(parseFloat(parts[20]) * 1000000)
        }

        // push to d dictionary
        D2OptionInfo.set(symbol, optionData)

        // push to u dictionary
        if (!U2OptionInfos.has(underlying))
            U2OptionInfos.set(underlying, {call: [], put: []})
        U2OptionInfos.get(underlying)![optionData.option_side_ === OptionSide.kCallorBull?"call":"put"].push(optionData)

        // update issuer list
        if (Issuers.indexOf(optionData.issuer) === -1)
            Issuers.push(optionData.issuer)
    })

    return true
}

export { LoadCBBC, LoadWarrant, Issuers, D2OptionInfo, U2OptionInfos }