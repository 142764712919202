enum ClientMessageType {
    kPing           = 0,
    kLogon          = 1,
    kLogonCheck     = 2,
    kLogout         = 3,

    // Subscription
    kSubscribeQuote                     = 10,
    kSubscribeTrade                     = 11,
    kSubscribeTradeQauntityByMinutes    = 12,

    kCleanSubscription                  = 99,
    // End of Subscription

    // Retrieve
    kRetrieveJumpPoint                  = 100,
    kRetrieveKlineBar                   = 101,
    kRetrieveTradeQuantityByMinutes     = 102,
    // End of Retrieve

    // Type of Data
    kQuote                              = 1000,
    kTrade                              = 1001,
    kTradeQauntityByMinutes             = 1002,

    kUnknown                            = 0xFFFFFFFF
}

enum SubscribeInteval {
    kOnce       = 0,
    kSecond     = 1,
    k5Second    = 5,
    k15Second   = 15,
    k30Second   = 30,
    kMinute     = 60
}

enum Side {
    kBid = 0,
    kAsk = 1,
    kUnknown = 0xFF
}

enum OptionType {
    kWarrant    = 0,
    kCBBC       = 1
}

enum OptionSide {
    kCallorBull = 0,
    kPutorBear  = 1
}

export { ClientMessageType, SubscribeInteval, Side, OptionType, OptionSide }