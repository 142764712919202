import { useCallback, useState, useMemo, useContext } from "react"

import { Side } from "../../../api/enum";
import { TradeQuantityByMinutes } from "../../../api/types";
import { ClientQuoteResponse } from "../../../api/response";
import { U2OptionInfos } from "../../../functions/OptionInfo";
import { LanguageMap } from "../../../functions/Language";
import { APIContext } from "../..";

import { Button, Card, Form, InputGroup, Table, Modal } from "react-bootstrap";

export interface ControlPanelProps {
    quotes: {[key:string]: ClientQuoteResponse|undefined }
    tradeQuantityByMinutes: {[key:string]: TradeQuantityByMinutes[]|undefined}
    setSelectedUnderlying: React.Dispatch<React.SetStateAction<number|null>>
    setSelectedSide: React.Dispatch<React.SetStateAction<Side|null>>
}

const DEFAULT_MULTIPLIER : number = 1
const DEFAULT_MINUTES    : number = 5

function LoadUList(){
    const localConfig = localStorage.getItem("trading_quantity_screener_ulist")
    if (localConfig)
        return JSON.parse(localConfig) as number[]
    return Array.from(U2OptionInfos.keys()).map(x=>x).sort((a,b)=>a-b).filter(x=>x<10000)
}

function ControlPanel({ quotes, tradeQuantityByMinutes, setSelectedUnderlying, setSelectedSide }: ControlPanelProps) {
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.trade_quantity_screener.control_panel, [language])

    // ui
    const [uiSelectedSide, setUISelectedSide] = useState<Side|null>(null)

    // config
    const ulist = useMemo(()=>Array.from(U2OptionInfos.keys()).filter((v)=>v<10000).sort((a,b)=>a-b), [])
    const [selectedUList, setSelectedUList] = useState<number[]>(LoadUList())
    const [showResetModal, setShowResetModal] = useState<boolean>(false)
    const resetUList = useCallback(()=>{
        localStorage.setItem("trading_quantity_screener_ulist", JSON.stringify(ulist))
        setSelectedUList(ulist)
        setShowResetModal(false)
    }, [ulist])
    const modifyUlist = useCallback((u: number, enable: boolean)=>{
        setSelectedUList((list)=>{
            if (!enable) {
                const newlist = list.filter((x)=>u!==x)
                localStorage.setItem("trading_quantity_screener_ulist", JSON.stringify(newlist))
                return newlist
            }

            if (list.indexOf(u) === -1) {
                const newlist = [...list, u]
                localStorage.setItem("trading_quantity_screener_ulist", JSON.stringify(newlist))
                return newlist
            }

            return list
        })
    }, [])

    // value
    const [filter, setFilter] = useState<boolean>(false)
    const [side, setSide] = useState<Side|null>(null)
    const [multiplier, setMultiplier] = useState<number>(DEFAULT_MULTIPLIER)
    const [minutes, setMinutes] = useState<number>(DEFAULT_MINUTES)
    
    const results = useMemo(()=>{
        const filteredU : {underlying: number, multiplier?: number}[] = Object.entries(tradeQuantityByMinutes).filter((v)=>U2OptionInfos.has(parseInt(v[0])) && Object.keys(quotes).indexOf(v[0]) !== -1).map((v)=>{
            const underlying = parseInt(v[0])
            // hide which is not in list
            if (selectedUList.indexOf(underlying) === -1) return { underlying: underlying }

            const trade_quantity_by_minutes_ = v[1]!
            
            if (!filter) return { multiplier: 0, underlying: underlying }
            if (trade_quantity_by_minutes_.length < minutes + 1) return { underlying: underlying }

            const start = trade_quantity_by_minutes_.length - minutes - 1; const end = start + minutes;
            const prev_trade_quantity_total = trade_quantity_by_minutes_.reduce((p, c, i)=>{
                if (i >= start && i < end) {
                    if (side === Side.kBid) return p + c.sell_quantity_
                    if (side === Side.kAsk) return p + c.buy_quantity_
                    return p + c.buy_quantity_ + c.sell_quantity_
                }
                return p
            }, 0)
            if (prev_trade_quantity_total === 0) return { underlying: underlying } // filter when no trade in prev n minutes

            const latest_data = trade_quantity_by_minutes_[trade_quantity_by_minutes_.length - 1]
            const current_trade_quantity = side === null ? latest_data.buy_quantity_ + latest_data.sell_quantity_ : side === Side.kBid ? latest_data.sell_quantity_ : latest_data.buy_quantity_

            if (multiplier * prev_trade_quantity_total < current_trade_quantity) {
                return { underlying: underlying, multiplier: current_trade_quantity / prev_trade_quantity_total }
            }
            return { underlying: underlying }
        }).filter((v)=>typeof(v.multiplier) !== "undefined")

        return filteredU
    }, [quotes, selectedUList, tradeQuantityByMinutes, filter, side, multiplier, minutes])

    const onsubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((event)=>{
        event.preventDefault()
        event.stopPropagation()

        const formData = new FormData(event.currentTarget)
        const multiplier = parseFloat(formData.get("multiplier")!.toString())
        const minutes = parseInt(formData.get("minutes")!.toString())

        setFilter(true)
        setMultiplier(multiplier)
        setSide(uiSelectedSide)
        setMinutes(minutes)
    }, [uiSelectedSide])

    const onUnderlyingSelect = useCallback((symbol: number)=>{
        setSelectedUnderlying(symbol)
        setSelectedSide(side)
    }, [setSelectedUnderlying, setSelectedSide, side])

    return useMemo(()=>{
        return (
            <Card style={{flex: 3, minWidth: 360, flexShrink: 0}}>
                <Card.Header>{textMap.name}</Card.Header>
                <Card.Body style={{display: "flex", flexDirection: "row", maxHeight: "28.75rem", gap: 16, padding: "0.75rem"}}>
                    <div style={{flex: 1, display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                        <Form onSubmit={onsubmit} style={{flex: 0, display: "flex", flexDirection: "column", gap: "0.5rem"}}>
                            <span>{textMap.trade_quantity_search}</span>
                            <InputGroup>
                                <Button style={{flex: 4}} className={uiSelectedSide===Side.kBid?"btn-danger":"btn-secondary"} onClick={()=>setUISelectedSide(Side.kBid)}>
                                    {textMap.hit_bid}
                                </Button>
                                <Button style={{flex: 3}} className={uiSelectedSide===null?"btn-primary":"btn-secondary"} onClick={()=>setUISelectedSide(null)}>
                                    {textMap.total}
                                </Button>
                                <Button style={{flex: 4}} className={uiSelectedSide===Side.kAsk?"btn-success":"btn-secondary"} onClick={()=>setUISelectedSide(Side.kAsk)}>
                                    {textMap.hit_ask}
                                </Button>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>{textMap.multiplier}</InputGroup.Text>
                                <Form.Control type="number" name="multiplier" min={0.25} step={0.25} required defaultValue={DEFAULT_MULTIPLIER}/>
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text>{textMap.minutes}</InputGroup.Text>
                                <Form.Control type="number" name="minutes" min={1} max={30} step={1} required defaultValue={DEFAULT_MINUTES}/>
                            </InputGroup>
                            <Button type="submit">{textMap.search}</Button>
                        </Form>
                        <div style={{flex: 1, overflowY: "auto"}}>
                            <Modal show={showResetModal}>
                                <Modal.Header>
                                    <Modal.Title>{textMap.confirmReset}</Modal.Title>
                                </Modal.Header>
                                <Modal.Footer>
                                    <Button onClick={()=>resetUList()}>{textMap.yes}</Button>
                                    <Button onClick={()=>setShowResetModal(false)}>{textMap.no}</Button>
                                </Modal.Footer>
                            </Modal>
                            <Table border={1} striped size="sm" style={{marginBottom: 0}}>
                                <thead className="bg-light sticky-top top-0" style={{boxShadow: "0 -1px 0 #dee2e6, inset 0 -1px 5px #dee2e6"}}>
                                    <tr>
                                        <th>{textMap.screeningUCode}</th>
                                        <th style={{textAlign: "right"}}>
                                            <Button size="sm" style={{padding: "0px 8px", fontSize: 14, width: 80}} onClick={()=>setShowResetModal(true)}>{textMap.reset}</Button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ulist.map((x)=>{
                                            return (
                                                <tr key={x}>
                                                    <td>{x}</td>
                                                    <td style={{textAlign: "right"}}>
                                                        <Form.Check type="switch" checked={selectedUList.indexOf(x) !== -1} onChange={(e)=>modifyUlist(x, e.currentTarget.checked)}/>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        <Table border={1} striped size="sm" style={{marginBottom: 0}}>
                            <thead className="bg-light sticky-top top-0" style={{boxShadow: "0 -1px 0 #dee2e6, inset 0 -1px 5px #dee2e6"}}>
                                <tr>
                                    <th>{textMap.ucode}</th>
                                    <th style={{textAlign: "right"}}>{textMap.multix}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    results.map((v)=>{
                                        if (typeof(v.multiplier) === "undefined") return null

                                        return (
                                            <tr key={v.underlying}>
                                                <td>{v.underlying}</td>
                                                <td style={{textAlign: "right"}}>{(Math.round(v.multiplier * 100) / 100).toFixed(2)}</td>
                                                <td style={{textAlign: "right"}}>
                                                    <Button style={{padding: "0px 8px", fontSize: 14}} onClick={()=>onUnderlyingSelect(v.underlying)}>{textMap.select}</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        )
    }, [textMap, ulist, modifyUlist, resetUList, selectedUList, showResetModal, uiSelectedSide, onsubmit, results, onUnderlyingSelect])
}

export default ControlPanel