// function
import { useCallback, useContext, useEffect, useMemo, useRef } from "react"

// private function
import { Side } from "../../../api/enum";
import { TradeQuantityByMinutes } from "../../../api/types";
import { DateStringType, From_Nanosecond_To_DateString } from "../../../functions/DateTimeHandler"
import { LanguageMap } from "../../../functions/Language";
import { APIContext } from "../..";

// ui component
import { Button, Card, Form, InputGroup, Table } from "react-bootstrap";


export interface OrderBookProps {
    selectedUnderlying: number | null
    setSelectedUnderlying: React.Dispatch<React.SetStateAction<number|null>>
    setSelectedSide: React.Dispatch<React.SetStateAction<Side|null>>
    trade_quantity_by_minutes: TradeQuantityByMinutes[] | null
}

function TradeQuantityList({ selectedUnderlying, setSelectedUnderlying, setSelectedSide, trade_quantity_by_minutes }: OrderBookProps){
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.trade_quantity_screener.trade_quantity_list, [language])

    const UnderlyingInput = useRef<HTMLInputElement>(null)
    useEffect(()=>{
        if (UnderlyingInput.current && selectedUnderlying)
            UnderlyingInput.current.value = selectedUnderlying.toString()
    }, [selectedUnderlying])

    const onsubmit = useCallback<React.FormEventHandler<HTMLFormElement>>((event) => {
        event.preventDefault()
        event.stopPropagation()

        const formData = new FormData(event.currentTarget)
        const underlying = parseInt((formData.get("underlying")??"NaN").toString())

        if (!isNaN(underlying) || underlying < 10000 || underlying > 0){
            setSelectedUnderlying(underlying)
            setSelectedSide(null)
        }
    }, [setSelectedUnderlying, setSelectedSide])

    return useMemo(()=>{
        const style : React.CSSProperties = {flex: 2, minWidth: 300, flexShrink: 0}

        const max_quantity = (trade_quantity_by_minutes??[]).reduce((p,c)=>{
            const quantity = Math.max(c.buy_quantity_ , c.sell_quantity_)
            return p > quantity ? p : quantity
        }, 0)

        return (
            <Card style={style} key={selectedUnderlying}>
                <Card.Header>{textMap.name}</Card.Header>
                <Card.Body style={{display: "flex", flexDirection: "column", gap: 8, maxHeight: "28.75rem", padding: "0.75rem"}}>
                    <Form style={{flex: 0, display: "flex", gap: 8}} onSubmit={onsubmit}>
                        <InputGroup size="sm" style={{maxWidth: 150}}>
                            <InputGroup.Text>U</InputGroup.Text>
                            <Form.Control type="text" name="underlying" ref={UnderlyingInput} />
                        </InputGroup>
                        <Button type="submit" size="sm">{textMap.search}</Button>
                    </Form>
                    <div style={{flex: 1, overflowY: "auto"}}>
                        {
                            selectedUnderlying === null ? <Card.Text>{textMap.no_underlying_selected}</Card.Text> :
                            <Table bordered hover size="sm" style={{marginBottom: 0}}>
                                <thead className="bg-light sticky-top top-0" style={{boxShadow: "0 -1px 0 #dee2e6, inset 0 -1px 5px #dee2e6"}}>
                                    <tr>
                                        <th style={{textAlign: "center", width: "30%"}}>{textMap.time}</th>
                                        <th style={{textAlign: "center", width: "35%"}}>{textMap.hit_bid}</th>
                                        <th style={{textAlign: "center", width: "35%"}}>{textMap.hit_ask}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (trade_quantity_by_minutes??[]).map((v, i)=>{
                                            const hit_bid_ratio = v.sell_quantity_ / max_quantity
                                            const hit_ask_ratio = v.buy_quantity_ / max_quantity

                                            return (
                                                <tr key={Number(v.timestamp_)}>
                                                    <td style={{textAlign: "center"}}>{From_Nanosecond_To_DateString(v.timestamp_ * BigInt(1000000), DateStringType.hhmmss)}</td>
                                                    <td style={{textAlign: "left", padding: 0}}>
                                                        <div style={{width: "100%", position: "relative", lineHeight: "2rem", textAlign: "right"}}>
                                                            <span style={{position: "relative", zIndex: 2, paddingRight: "0.25rem"}}>{v.sell_quantity_}</span>
                                                            <div style={{display: hit_bid_ratio===0?"none":"block", minWidth: (hit_bid_ratio * 100).toString()+"%", position: "absolute", top: 0, left: 0, zIndex: 0, backgroundColor: "rgba(255,0,0,0.3)"}}>&nbsp;</div>
                                                        </div>
                                                    </td>
                                                    <td style={{textAlign: "left", padding: 0}}>
                                                        <div style={{width: "100%", position: "relative", lineHeight: "2rem", textAlign: "right"}}>
                                                            <span style={{position: "relative", zIndex: 2, paddingRight: "0.25rem"}}>{v.buy_quantity_}</span>
                                                            <div style={{display: hit_ask_ratio===0?"none":"block", minWidth: (hit_ask_ratio * 100).toString()+"%", position: "absolute", top: 0, right: 0, zIndex: 0, backgroundColor: "rgba(0,255,0,0.3)"}}>&nbsp;</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }).reverse()
                                    }
                                </tbody>
                            </Table>
                        }
                    </div>
                </Card.Body>
            </Card>
        )
    }, [textMap, onsubmit, selectedUnderlying, trade_quantity_by_minutes])
}

export default TradeQuantityList