const StringProcessor = {
    ByteParser(view: DataView, pos: number, size: number) {
        const uint8Array = new Uint8Array(new ArrayBuffer(size))

        let endIndex = size
        for (let i = 0; i < size; i++) {
            const v = view.getUint8(pos + i)
            uint8Array[i] = v;

            if (v === 0) {
                endIndex = i
                break
            }
        }

        return new TextDecoder('utf-8').decode(uint8Array.subarray(0, endIndex))
    }
    ,
    StringCopyer(view: DataView, str: string, pos: number, size: number) {
        const encoder = new TextEncoder()
        const stringbuf = encoder.encode(str)

        for (let i = 0; i < size; i++){
            if (i >= stringbuf.byteLength){
                view.setUint8(pos + i, 0)
                continue
            }
            view.setUint8(pos + i, stringbuf[i])
        }
    }
}

export { StringProcessor }