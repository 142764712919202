import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './pages';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

if (process.env.NODE_ENV === 'production') {
	// console.log = () => {}
	// console.error = () => {}
	console.debug = () => {}
}

const browserRouter = createBrowserRouter([
	{
		path: "/*",
		element: <App/>
	}
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<RouterProvider router={browserRouter}/>
	</React.StrictMode>
);