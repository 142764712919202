// function
import { useEffect, useRef, useCallback, useContext, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"

// private function
import { ClientMessageType, Side, SubscribeInteval } from "../../api/enum";
import { TradeQuantityByMinutes } from "../../api/types";
import { ClientRetrieveTradeQuantityByMinutesRequest, ClientSubscribeQuoteRequest, ClientSubscribeTradeQuantityByMinutesRequest } from "../../api/request";
import { ClientResponseContainer, ClientQuoteResponse, ClientRetrieveJumpPointResponse, ClientRetrieveTradeQuantityByMinutesResponse, ClientTradeQuantityByMinutesResponse } from "../../api/response";
import { U2OptionInfos } from "../../functions/OptionInfo";

// private ui component
import ControlPanel from "./component/ControlPanel";
import TradeQuantityList from "./component/TradeQuantityList";
import OptionList from "../../component/OptionList";
import JumpPointList from "../../component/JumpPointList";

// context
import { APIContext } from "..";

function TradeQuantityScreenerPage() {
    const { isLogon, endpoint } = useContext(APIContext)
    const navigate = useNavigate()

    // raw data
    const quoteRef = useRef<{[key:string]: ClientQuoteResponse|undefined}>({})
    const [quotes, setQuotes] = useState<{[key:string]: ClientQuoteResponse|undefined}>({})
    const tradeQuantityByMinutesRef = useRef<{[key:string]: TradeQuantityByMinutes[]|undefined}>({})
    const [tradeQuantityByMinutes, setTradeQuantityByMinutes] = useState<{[key:string]: TradeQuantityByMinutes[]|undefined}>({})
    const jumppointRef = useRef<{[key:string]: ClientRetrieveJumpPointResponse}>({})
    const [jumppoints, setJumpPoints] = useState<{[key:string]: ClientRetrieveJumpPointResponse}>({})

    const onmessage = useCallback((container: ClientResponseContainer)=>{
        if (container.message_header_.message_type_ === ClientMessageType.kQuote) {
            const resp = new ClientQuoteResponse(container)
            // console.debug(resp)
            quoteRef.current[resp.symbol_.toString()] = resp
            setQuotes(()=>Object.assign({}, quoteRef.current))
        }
        else if (container.message_header_.message_type_ === ClientMessageType.kTradeQauntityByMinutes) {
            const resp = new ClientTradeQuantityByMinutesResponse(container)
            const list = tradeQuantityByMinutesRef.current[resp.symbol_.toString()]!
            const index = tradeQuantityByMinutesRef.current[resp.symbol_.toString()]!.findIndex((x)=>x.timestamp_ === resp.trade_quantity_by_minutes_.timestamp_)

            // update or push new TradeQauntityBtMinutes into the list
            if (index >= 0) {
                list[index] = resp.trade_quantity_by_minutes_;
            } else {
                list.push(resp.trade_quantity_by_minutes_)
            }

            // replace the list
            // console.debug(resp, list)
            tradeQuantityByMinutesRef.current[resp.symbol_.toString()] = [...list]
            setTradeQuantityByMinutes(()=>Object.assign({}, tradeQuantityByMinutesRef.current))
        }
        else if (container.message_header_.message_type_ === ClientMessageType.kRetrieveTradeQuantityByMinutes) {
            const resp = new ClientRetrieveTradeQuantityByMinutesResponse(container)
            // console.debug(resp)
            tradeQuantityByMinutesRef.current[resp.symbol_.toString()] = resp.trade_quantity_by_minutes_
            setTradeQuantityByMinutes(()=>Object.assign({}, tradeQuantityByMinutesRef.current))
        }
        else if (container.message_header_.message_type_ === ClientMessageType.kRetrieveJumpPoint) {
            const resp = new ClientRetrieveJumpPointResponse(container)
            jumppointRef.current[resp.symbol_] = resp
            setJumpPoints(()=>Object.assign({}, jumppointRef.current))
        }
    }, [])

    const SendSubscribeUnderlyingRequest = useRef<boolean>(false)
    useEffect(() => {
        // back to index if logged out
        if (isLogon === false) {
            navigate("/");
            return
        }

        if (endpoint.on_message !== onmessage) endpoint.on_message = onmessage

        if (SendSubscribeUnderlyingRequest.current === false) {
            SendSubscribeUnderlyingRequest.current = true

            // send multiple subscribe message for each underlying
            endpoint.send([
                ...Array.from(U2OptionInfos.keys()).map(u => new ClientSubscribeQuoteRequest(u, SubscribeInteval.k30Second, true)),
                ...Array.from(U2OptionInfos.keys()).map(u => new ClientRetrieveTradeQuantityByMinutesRequest(u)),
                ...Array.from(U2OptionInfos.keys()).map(u => new ClientSubscribeTradeQuantityByMinutesRequest(u, SubscribeInteval.kSecond, true))
            ])
        }
    }, [isLogon, endpoint, navigate, onmessage])

    // status
    const [selectedUnderlying, setSelectedUnderlying] = useState<number|null>(null)
    const [selectedSide, setSelectedSide] = useState<Side|null>(null)
    const [selectedOption, setSelectedOption] = useState<number|null>(null)

    const trade_quantity_by_minutes_from_selectedUnderlying = useMemo(()=>{
        if (selectedUnderlying === null) return null
        if (Object.keys(tradeQuantityByMinutes).indexOf(selectedUnderlying.toString()) === -1) return null
        return tradeQuantityByMinutes[selectedUnderlying]!
    }, [selectedUnderlying, tradeQuantityByMinutes])

    const jumppoint_from_selectedOption = useMemo(()=>{
        if (selectedOption === null) return null
        if (Object.keys(jumppoints).indexOf(selectedOption.toString()) === -1) return null
        return jumppoints[selectedOption]
    }, [selectedOption, jumppoints])

    return useMemo(()=>{
        return (
            <div style={{display: "flex", flexDirection: "column", gap: 16}}>
                <div style={{flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 16, height: "fit-content"}}>
                    <ControlPanel quotes={quotes} tradeQuantityByMinutes={tradeQuantityByMinutes} setSelectedUnderlying={setSelectedUnderlying} setSelectedSide={setSelectedSide}/>
                    <TradeQuantityList selectedUnderlying={selectedUnderlying} setSelectedUnderlying={setSelectedUnderlying} setSelectedSide={setSelectedSide} trade_quantity_by_minutes={trade_quantity_by_minutes_from_selectedUnderlying}/>
                    <OptionList quotes={quotes} underlying={selectedUnderlying} side={selectedSide} setSelectedOption={setSelectedOption}/>
                </div>
                <div style={{flex: 1}}>
                    <JumpPointList selectedOption={selectedOption} jumppoint={jumppoint_from_selectedOption} setSelectedOption={setSelectedOption}/>
                </div>
            </div>
        )
    }, [quotes, tradeQuantityByMinutes, trade_quantity_by_minutes_from_selectedUnderlying, jumppoint_from_selectedOption, selectedUnderlying, selectedSide, selectedOption])
}

export default TradeQuantityScreenerPage