import { useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { APIContext } from "..";

import { Button, Card } from "react-bootstrap";
import { ClientCleanSubscriptionRequest } from "../../api/request";

function HomePage(){
    const { isLogon, endpoint } = useContext(APIContext)
    const navigate = useNavigate()

    const SendCleanSubscription = useRef<boolean>(false)
    useEffect(()=>{
        if (isLogon === false) navigate("/login")

        if (SendCleanSubscription.current === false){
            SendCleanSubscription.current = true
            endpoint.send(new ClientCleanSubscriptionRequest())
        }
    }, [isLogon, endpoint, navigate])

    const features = useMemo(()=>{
        return [
            {name: "Orderbook Screener", path: "orderbookscreener"},
            {name: "Trade Quantity Screener", path: "tradequantityscreener"},
            {name: "Tutorial", path: "tutorial"}
        ]
    }, [])
    
    return useMemo(()=>{
        if (!isLogon) {
            return <div></div>
        }

        return (
            <div style={{display: "flex", flexWrap: "wrap", width: "fit-content", maxWidth: "95%", gap: 32, margin: "auto"}}>
                {
                    features.map((x)=>{
                        return (
                            <Card key={x.path} style={{ flex: 1, minWidth: 320}}>
                                <Card.Body>
                                    <Card.Title>{x.name}</Card.Title>
                                    <Button onClick={()=>navigate(x.path)}>Go</Button>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </div>
        )
    }, [navigate, isLogon, features])
}

export default HomePage