import { LoadSpreadTableMap } from "./SpreadTable"
import { LoadWarrant, LoadCBBC } from "./OptionInfo"
import React from "react"

async function LoadRef(setRefLoaded: React.Dispatch<React.SetStateAction<boolean | null>>) {
    if (!await LoadSpreadTableMap()) {
        setRefLoaded(false)
        return
    }
    if (!await LoadWarrant()) {
        setRefLoaded(false)
        return
    }
    if (!await LoadCBBC()) {
        setRefLoaded(false)
        return
    }

    setRefLoaded(true)
    return
}

export { LoadRef }