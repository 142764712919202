enum SpreadTableType {
    Normal = 0,
    ETF = 1,
    Cash = 2
}

const SpreadTableMap : {[key:string] : SpreadTableType} = {}

async function LoadSpreadTableMap() {
    if (Object.keys(SpreadTableMap).length !== 0) return

    const rawResp = await fetch("https://archive.aq-techs.com/csv/spread_table_map.csv")
    if (!rawResp.ok || rawResp.status >= 300) return false

    const resp = await rawResp.text()

    const lines = resp.split("\n")

    lines.forEach((x)=>{
        const trimedLine = x.trim()
        if (trimedLine.length === 0) return

        const parts = trimedLine.split(",")
        SpreadTableMap[parts[0]] = (
            parts[1].startsWith("D") ? SpreadTableType.ETF : (parts[1].startsWith("B") ? SpreadTableType.Cash : SpreadTableType.Normal)
        )
    })

    return true
}

function GetSpreadTableType(symbol: string | number) {
    if (typeof(symbol) === "string")
        symbol = symbol.trim()
    if (typeof(symbol) === "number")
        symbol = symbol.toString()

    if (Object.keys(SpreadTableMap).indexOf(symbol) === -1)
        return SpreadTableType.Normal

    return SpreadTableMap[symbol]
}

function GetSpread(price: number, spread_table_type ?: SpreadTableType) {
    if (spread_table_type === SpreadTableType.ETF){
        if (price < 10)         return 0
        if (price < 1000)       return 1
        if (price < 5000)       return 2
        if (price < 10000)      return 5
        if (price < 20000)      return 10
        if (price < 100000)     return 20
        if (price < 200000)     return 50
        if (price < 500000)     return 100
        if (price < 1000000)    return 200
        if (price < 2000000)    return 500
        return 1000
    }
    else if (spread_table_type === SpreadTableType.Cash){
        return 50
    }

    // SpreadTableType.Normal
    if (price < 10)         return 0
    if (price < 250)        return 1
    if (price < 500)        return 5
    if (price < 10000)      return 10
    if (price < 20000)      return 20
    if (price < 100000)     return 50
    if (price < 200000)     return 100
    if (price < 500000)     return 200
    if (price < 1000000)    return 500
    return 1000
}

function ReturnNextUpPrice(price: number, spread_table_type: SpreadTableType) {
    let spread = 0;
    if (spread_table_type === SpreadTableType.Normal){
        if (price < 250)            spread = 1
        else if (price < 500)       spread = 5
        else if (price < 10000)     spread = 10
        else if (price < 20000)     spread = 20
        else if (price < 100000)    spread = 50
        else if (price < 200000)    spread = 100
        else if (price < 500000)    spread = 200
        else if (price < 1000000)   spread = 500
        else                        spread = 1000
    }
    else if (spread_table_type === SpreadTableType.ETF){
        if (price < 1000)           spread = 1
        else if (price < 5000)      spread = 2
        else if (price < 10000)     spread = 5
        else if (price < 20000)     spread = 10
        else if (price < 100000)    spread = 20
        else if (price < 200000)    spread = 50
        else if (price < 500000)    spread = 100
        else if (price < 1000000)   spread = 200
        else if (price < 2000000)   spread = 500
        else                        spread = 1000
    }
    else if (spread_table_type === SpreadTableType.Cash){
        spread = 50
    }

    return price + spread
}

function ReturnNextDownPrice(price: number, spread_table_type: SpreadTableType) {
    let spread = 0
    if (spread_table_type === SpreadTableType.Normal){
        if (price <= 10)            spread = 0
        else if (price <= 250)      spread = 1
        else if (price <= 500)      spread = 5
        else if (price <= 10000)    spread = 10
        else if (price <= 20000)    spread = 20
        else if (price <= 100000)   spread = 50
        else if (price <= 200000)   spread = 100
        else if (price <= 500000)   spread = 200
        else if (price <= 1000000)  spread = 500
        else spread = 1000
    }
    else if (spread_table_type === SpreadTableType.ETF){
        if (price <= 10)            spread = 0
        else if (price <= 1000)     spread = 1
        else if (price <= 5000)     spread = 2
        else if (price <= 10000)    spread = 5
        else if (price <= 20000)    spread = 10
        else if (price <= 100000)   spread = 20
        else if (price <= 200000)   spread = 50
        else if (price <= 500000)   spread = 100
        else if (price <= 1000000)  spread = 200
        else if (price <= 2000000)  spread = 500
        else spread = 1000
    }
    else if (spread_table_type === SpreadTableType.Cash){
        if (price <= 500)         spread = 0
        else                      spread = 50
    }

    if (spread === 0) return null
    return price - spread
}

function ReturnNextNUpPrice(price: number, n: number, spread_table_type: SpreadTableType) {
    let newPrice = price;
    for (let i = 0; i < n; i++)
        newPrice = ReturnNextUpPrice(newPrice, spread_table_type)
    return newPrice;
}

function ReturnNextNDownPrice(price: number, n: number, spread_table_type: SpreadTableType) {
    let newPrice : number | null = price;
    for (let i = 0; i < n; i++){
        newPrice = ReturnNextDownPrice(newPrice, spread_table_type)
        if (newPrice === null) return null
    }
    return newPrice;
}

function SpreadBetween(price1: number, price2: number, spread_table_type: SpreadTableType) {
    let priceLow = Math.min(price1, price2)
    let priceHigh = Math.max(price1, price2)

    let count = 0
    while (priceLow < priceHigh) {
        priceLow = ReturnNextUpPrice(priceLow, spread_table_type)
        count += 1
    }

    return count
}

export { LoadSpreadTableMap, GetSpread, GetSpreadTableType, ReturnNextUpPrice, ReturnNextDownPrice, ReturnNextNUpPrice, ReturnNextNDownPrice, SpreadBetween }