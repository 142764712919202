import { useContext, useMemo } from "react"

import { LanguageMap } from "../../../functions/Language"
import { APIContext } from "../.."

import { Card } from "react-bootstrap";

import main from "../image/orderbookscreener/main.png"
import control_panel from "../image/orderbookscreener/control_panel.png"
import control_panel_side from "../image/orderbookscreener/control_panel_side.png"
import control_panel_multiplier from "../image/orderbookscreener/control_panel_multiplier.png"
import control_panel_level from "../image/orderbookscreener/control_panel_level.png"
import control_panel_searchresult from "../image/orderbookscreener/control_panel_searchresult.png"
import order_book from "../image/orderbookscreener/order_book.png"
import option_list from "../image/orderbookscreener/option_list.png"
import option_list_select_option from "../image/orderbookscreener/option_list_select_option.png"
import jump_point_list from "../image/orderbookscreener/jump_point_list.png"
import jump_point_list_symbol from "../image/orderbookscreener/jump_point_list_symbol.png"
import jump_point_list_display_control from "../image/orderbookscreener/jump_point_list_display_control.png"

function OrderbookScreenerTutorial(){
    const { language } = useContext(APIContext)
    const textMap = useMemo(()=>LanguageMap.get(language)!.page.tutorial.orderbook_screener, [language])

    return useMemo(()=>{
        return (
            <Card>
                <Card.Header>{textMap.title}</Card.Header>
                <Card.Body>
                        <Card.Title>{textMap.main}</Card.Title>
                        <img src={main} alt="warrant screener page snapshot" style={{maxWidth: "100%"}}/>
                    <hr/>
                        <Card.Title>{textMap.control_panel}</Card.Title>
                        <img src={control_panel} alt="control panel snapshot" style={{maxWidth: "100%"}}/>
                        <Card.Text>這裡是控制面板，它可以根據你輸入的條件尋找可能被擊穿幾層或存在支撐的正股。</Card.Text>
                        <img src={control_panel_side} alt="control panel side control" style={{maxWidth: "100%"}}/>
                        <Card.Text>1. 選擇檢查買盤（向下擊穿）或沽盤（向上擊穿）</Card.Text>
                        <img src={control_panel_multiplier} alt="control panel multiplier" style={{maxWidth: "100%"}}/>
                        <Card.Text>2. 輸入被檢查的層數的貨量需要大於後幾層總和的多少倍</Card.Text>
                        <img src={control_panel_level} alt="control panel level" style={{maxWidth: "100%"}}/>
                        <Card.Text>3. 輸入被檢查的層數需要與後面多少層進行比較</Card.Text>
                        <Card.Text>4. 點擊"Search"按鈕進行搜尋</Card.Text>
                        <img src={control_panel_searchresult} alt="control panel search result list" style={{maxWidth: "100%"}}/>
                        <Card.Text>5. 當完成搜尋或搜尋結果更新時，搜尋結果會顯示在列表中。</Card.Text>
                        <Card.Text>6. 點擊"Select"按鈕，該正股的盤口數據會顯示在"盤口數據"功能上，而對應方向的窩輪及牛熊會顯示在"窩輪列表"功能上。</Card.Text>
                    <hr/>
                        <Card.Title>{textMap.order_book}</Card.Title>
                        <img src={order_book} alt="orderbook" style={{maxWidth: "100%"}}/>
                        <Card.Text>當你在控制面板點擊了正股旁邊的"Select"按鈕，本功能會顯示對應的盤口數據。</Card.Text>
                    <hr/>
                        <Card.Title>{textMap.option_list}</Card.Title>
                        <img src={option_list} alt="option list" style={{maxWidth: "100%"}}/>
                        <Card.Text>當你在控制面板點擊了正股旁邊的"Select"按鈕，本功能會顯示對應方向的窩輪及牛熊列表，價格過低的窩輪及牛熊會被自動移除。</Card.Text>
                        <img src={option_list_select_option} alt="option list d-code select" style={{maxWidth: "100%"}}/>
                        <Card.Text>你可以點擊窩輪/牛熊代號，對應的轉價位資訊會在"轉價位"功能中顯示。</Card.Text>
                    <hr/>
                        <Card.Title>{textMap.jump_point_list}</Card.Title>
                        <img src={jump_point_list} alt="option list" style={{maxWidth: "100%"}}/>
                        <Card.Text>當你在窩輪列表點擊了窩輪/牛熊代號，本功能會顯示該窩輪/牛熊最近100次被系統保存的轉價位。</Card.Text>
                        <img src={jump_point_list_symbol} alt="option list symbol input" style={{maxWidth: "100%"}}/>
                        <Card.Text>你也可以透過更改代號並點擊"Search"按鈕取得轉價位資訊。</Card.Text>
                        <img src={jump_point_list_display_control} alt="option list display control" style={{maxWidth: "100%"}}/>
                        <Card.Text>透過開啟或關閉貨量/比例選項可以決定是否顯示相關資訊。</Card.Text>
                </Card.Body>
            </Card>
        )
    }, [textMap])
}

export default OrderbookScreenerTutorial